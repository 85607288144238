import React, { useState } from 'react';
import { GET } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
import PreLoader from '../preLoader/PreLoader';
import moment from 'moment';

const subscriptionUrl = process.env.REACT_APP_SUBSCRIPTION_API;

export default function PlanRenewal() {
  const [loading, setLoading] = useState(false);
  let showRenew = localStorage.getItem("organisation_inRenewPeriod")==='true';

  let isFreeTrail = JSON.parse(localStorage.getItem('is_freemium_plan_used'));

  const requestCallBackHandler = async () => {
    setLoading(true);
    await GET(`${subscriptionUrl}v1/payment-link/renew`)
      .then((res) => {
        // console.log(res.data.response);
        window.location.href = res.data.response;
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
      });
  };

  return (
    <>
      {loading && <PreLoader />}
      {!isFreeTrail &&
        showRenew &&
        <div
          style={{
            fontSize: 13,
            color: '#196cca',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() => requestCallBackHandler()}
        >
          Renew now
        </div>}
    </>
  );
}
