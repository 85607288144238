import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { generateRecaptcha } from '../../../services/Recaptcha';
import { POST, GET } from '../../../services/HttpRequests';
import TwoFAAuthentication from '../component/TwoFAAuthentication';
import Logo from '../../../assets/images/alohaaLogoAndBottomText.png';
import InfoRed from '../../../assets/images/infoRed.png';
import Wall from '../../../assets/images/loginPageWall.png';
import '../../../assets/css/Login.css';
import '../../../assets/css/LoginNew.css';

const userUrl = process.env.REACT_APP_USER_API;
const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
class LoginPage extends Component {
  componentDidMount = async () => {
    const query = new URLSearchParams(this.props.location.search);
    const username = query.get('username');
    this.setState({ username: username ?? '' });
  };

  state = {
    checkboxChecked: false,
    step: 1,
    username: '',
    password: '',
    error: '',
    isLoading: false,
  };

  handleForgotPasswordClick = () => {
    this.props.history.push('/signup');
  };

  checkboxToggleHandler = () => {
    this.setState({ checkboxChecked: !this.state.checkboxChecked });
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  submitCredentialsHandler = async (e) => {
    if (e) {
      e.preventDefault();
    }
    const recaptcha = await generateRecaptcha('BUSINESS_LOGIN');
    let reqBody = {
      username: this.state.username,
      password: this.state.password,
      recaptchaToken: recaptcha,
    };

    this.setState({ isLoading: true });

    POST(`${userUrl}v1/auth/login`, reqBody)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        const {
          is_2FA_enabled,
          two_FA_secret = '',
          organisation_id,
          user_id,
        } = res.user;
        this.setState({
          step: 2,
          is_2FA_enabled,
          secret: two_FA_secret,
          organisation_id,
          user_id,
          ...res,
          isLoading: false,
        });
        return res;
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          this.setState({
            error: err.response.data.error.reason,
            isLoading: false,
          });
        }
      });
  };

  getFreeTrialInfo = async (orgId) => {
    console.log(orgId);
    await GET(`${orgUrl}v1/organisation/is-free-trial-details`, {
      organisationId: orgId,
    }).then((res) => {
      localStorage.setItem(
        'is_free_trial_enabled',
        res.data.response?.free_trial
      );
      localStorage.setItem(
        'is_kyc_submitted',
        res.data.response?.is_kyc_submitted
      );
      localStorage.setItem(
        'is_kyc_completed',
        !!res.data.response?.is_kyc_completed
      );
      console.log(res.data.response);
      return res.data.response;
    });
  };

  cancelHandler = (e) => {
    e.preventDefault();
  };

  navigateToSignup = () => {
    console.log('signup');
    this.props.history.push('/signup');
  };

  render() {
    const {
      step,
      username,
      password,
      error,
      checkboxChecked,
      is_2FA_enabled,
      secret,
      organisation_id,
      user_id,
      isLoading,
    } = this.state;

    if (
      localStorage.getItem('doosra-business-sessionToken') &&
      localStorage.getItem('doosra-business-sessionToken') !== ''
    ) {
      let isFreeTrail = localStorage.getItem('is_free_trial_enabled');
      isFreeTrail = isFreeTrail
        ? isFreeTrail === 'true'
          ? true
          : false
        : false;
      let userRole = localStorage.getItem('doosra-biz-user-role');
      if (isFreeTrail && userRole === 'ADMIN') {
        return <Redirect to="trial-period" />;
      }
      return <Redirect to="call-logs" />;
    }

    if (step === 2) {
      return (
        <TwoFAAuthentication
          is_2FA_enabled={is_2FA_enabled}
          secret={secret}
          error={error}
          password={password}
          organisation_id={organisation_id}
          user_id={user_id}
          resendOtp={this.submitCredentialsHandler}
          {...this.state}
        />
      );
    }

    return (
      <div className="login-main">
        <div className="section-1">
          <div className="login-container">
            <img className="login-container-logo" alt="" src={Logo} />
            <div className="AuthHeadContainer">
              <p className="AuthSubHeader">Log into your account </p>
            </div>

            <form onSubmit={this.submitCredentialsHandler}>
              <input
                id="username"
                className="InputBox"
                placeholder="Phone number"
                onChange={this.handleChange}
                value={username}
              />
              <input
                id="password"
                type="password"
                className="InputBox"
                placeholder="Password"
                onChange={this.handleChange}
                value={password}
              />
              <Link to="forgot-password">
                <p className="AuthForgotPassword">Forgot password</p>
              </Link>

              <div className="ErrorContainer ErrorContainerMargin">
                {error && (
                  <React.Fragment>
                    <img
                      alt=""
                      src={InfoRed}
                      style={{
                        paddingRight: '5px',
                        position: 'relative',
                        top: '-2px',
                      }}
                    />
                    {error}
                  </React.Fragment>
                )}
              </div>

              <button
                className={`AuthButton ${isLoading ? 'ButtonLoading' : ''}`}
                type="submit"
                disabled={isLoading}
              >
                <span className="ButtonText">{isLoading ? '' : 'NEXT'}</span>
                {isLoading && <span className="ButtonSpinner"></span>}
              </button>

              {/* <div style={{ margin: '16px 0' }}>
                <a
                  href="mailto:support@alohaa.ai"
                  className="AuthButton AuthButtonInvert GetInTouch"
                >
                  GET IN TOUCH
                </a>
              </div> */}
              <p style={{ fontWeight: 500 }}>
                New to Alohaa?{' '}
                <span
                  style={{
                    color: '#ed3833',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={this.handleForgotPasswordClick}
                >
                  {' '}
                  Sign up
                </span>
              </p>
              <p style={{ fontWeight: 500 }}>
                Read our{' '}
                <a
                  style={{
                    color: '#ed3833',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  target="_blank"
                  href="https://www.alohaa.ai/privacy-policy" rel="noreferrer"
                >
                  {' '}
                  Privacy Policy
                </a>
              </p>
            </form>

            {/* {this.props.location.pathname === '/selfsignup' && ( */}
            {/* <button className="SignupButton" onClick={this.navigateToSignup}>
              SIGNUP
            </button> */}
            {/* )} */}
          </div>
        </div>

        <img style={{ height: '100vh' }} src={Wall} alt="Wall" />
      </div>
    );
  }
}

export default LoginPage;
