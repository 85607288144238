import React from "react";
import AlohaaLogo from '../../assets/images/alohaaLogo.svg';
import Person from '../../assets/images/personWhite.png';


const CallFlowHeader = () => {
  return (
    <div className="CallFlowHeader">
        <button className="UnStyledButton">
          <img className="CallFlowHeaderLogo" style={{ marginLeft: "34px" }} alt="" src={AlohaaLogo} />
        </button>
        {/* <Profile></Profile> */}
        <button className="ProfileButton">
          <img  style={{ width: "16px", height: "16px",  }} alt="" src={Person} />
        </button>
    </div>
  )
};

export default CallFlowHeader;