import adapter from 'webrtc-adapter';
import JsSIP from 'jssip';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import DailPadIcon from '../../../assets/images/dialpad.svg';
import AlohaaLogo from '../../../assets/images/AlohaaLogoWhite.png';
import phoneWhite from '../../../assets/images/phoneWhite.png';
import callEndLogo from '../../../assets/images/call_end.svg';
import circleDown from '../../../assets/images/expand_circle_down.svg';
import MicOff from '../../../assets/images/mic_off.svg';
import Mic from '../../../assets/images/mic.svg';
import Play from '../../../assets/images/play_arrow.svg';
import Pause from '../../../assets/images/pause.svg';
import GreyPause from '../../../assets/images/GreyPause.svg';
import ExpandCircleDown from '../../../assets/images/expand_circle_up.svg';
import GreyMic from '../../../assets/images/GreyMic.svg';
import HangupLogo from '../../../assets/images/RecoveryHangup.webp';
import Help from '../../../assets/images/help.svg';
import { GET, POST, PUT } from '../../../services/HttpRequests';
import { useSocket } from '../../../providers/SocketProvider';
import { toast } from 'react-toastify';
import { TURN_SERVER_CONFIG } from '../../../assets/constants';
import { commonStyle } from '../../../assets/css/css';
import { connect, useSelector } from 'react-redux';
import { useTheme } from '../../../providers/customeThemeProvider';
import tickAnimaiton from '../../../assets/images/tickAnimation.json';
import GreenTick from '../../../assets/images/greenTickSmall.png';
import Lottie from 'react-lottie';
import './DailPad.css';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import {
  setIsOpen,
  setisMicOff,
  setIsPaused,
  setIsCallInProgress,
  setNumbers,
  setDestinationNumber,
  setDid,
  setCallType,
  setCallStatus,
  setUAList,
  setRTCSessionData,
  setDispositions,
  setTimer,
  setRunTimer,
  setCallId,
  setShowDailNextButton,
  setDailNextTimer,
  setContactId,
  setAgentStatus,
  setDisposition,
  setSelectedDailer,
  setAgentActiveCampaigns,
  setSelectedCampaign,
  setSelectedCampaignDetails,
  setShowCampaignExitScreen,
  setIsCampaignInProgress,
  setDestinationName,
  setBatchId,
  setCampaignCompletionStat,
  setCampaignId,
} from '../../../actions/dailPadActions';
import { green } from '@material-ui/core/colors';

const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
const cloudCallUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;
JsSIP.debug.enable('JsSIP:*');
const sip_details = JSON.parse(localStorage.getItem('sip_details'));
const username = sip_details?.username;
const password = sip_details?.password;

const DailerButton = styled.button`
  color: white;
  border: none;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DailerInProgress = styled.div`
  width: 263px;
  height: 54px;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(--Alohaa-Red, #f8153b);
`;

const DailerInProgressInfo = styled.div`
  width: 110px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #ba2c2c;
  position: absolute;
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const DailerInProgressInfoTimer = styled.label`
  color: var(--White, #fff);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  left: 133px;
  top: 20px;
  margin: 0px;
`;

const DailerInProgressEndButton = styled.div`
  width: 51px;
  height: 26px;
  flex-shrink: 0;
  border-radius: 13px;
  border: 1px solid var(--White, #fff);
  background: var(--Alohaa-Red, #f8153b);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 176px;
  top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const DailerInProgressNumber = styled.label`
  color: var(--White, #fff);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  position: absolute;
  line-height: normal;
`;

const GradientDiv = styled.div`
  height: 98px;
  background: linear-gradient(#f8153b, #920c23);
  display: flex;
  flex-direction: row;
  border-radius: 12px 12px 0px 0px;
`;

const DailerDiv = styled.div`
  height: ${(props) => (props.theme === 'light' ? '295px' : '333px')};
  background: ${(props) => (props.theme === 'light' ? 'white' : '#282828')};
  border-radius: ${(props) =>
    props.theme === 'light' ? '0px' : '0px 0px 10px 10px'};
`;

const ExitCampaignDiv = styled.div`
  height: ${(props) => (props.theme === 'light' ? '180px' : '333px')};
  background: ${(props) => (props.theme === 'light' ? 'white' : '#282828')};
  border-radius: ${(props) =>
    props.theme === 'light' ? '0px' : '0px 0px 10px 10px'};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DailButton = styled.div`
  width: 230px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 32px;
  background: ${(props) =>
    props.disabled ? 'grey' : 'var(--Alohaa-Green, #07aa3f)'};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const EndButton = styled.div`
  width: 230px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 32px;
  background: var(--Alohaa-Red, #f8153b);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: ${(props) => (props.type === 'power' ? '70px' : '16px')};
  margin: 0px 36px 0px 36px;
`;

const DailNextButton = styled.div`
  width: 230px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 2px solid #07aa3f;
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: ${(props) => (props.type === 'power' ? '70px' : '16px')};
  align-items: center;
  cursor: pointer;
  margin: 0px 36px 0px 36px;
`;

const ResumeCallingButton = styled.div`
  width: 230px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 2px solid #07aa3f;
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 52px};
  align-items: center;
  cursor: pointer;
  margin: 0px 36px 0px 36px;
`;

const DailPad = (props) => {
  let userRole = localStorage.getItem('doosra-biz-user-role');
  const { themeType } = useTheme();
  const isOpen = useSelector((state) => state.dailpad.isOpen);
  const isMicOff = useSelector((state) => state.dailpad.isMicOff);
  const isPaused = useSelector((state) => state.dailpad.isPaused);
  const isCallInProgress = useSelector(
    (state) => state.dailpad.isCallInProgress
  );
  const numbers = useSelector((state) => state.dailpad.numbers);
  const destinationNumber = useSelector(
    (state) => state.dailpad.destinationNumber
  );
  const destinationName = useSelector((state) => state.dailpad.destinationName);
  const did = useSelector((state) => state.dailpad.did);
  const callType = useSelector((state) => state.dailpad.callType);
  const callStatus = useSelector((state) => state.dailpad.callStatus);
  const UAList = useSelector((state) => state.dailpad.UAList);
  const RTCSessionData = useSelector((state) => state.dailpad.RTCSessionData);
  const dispositions = useSelector((state) => state.dailpad.dispositions);
  const disposition = useSelector((state) => state.dailpad.disposition);
  const timer = useSelector((state) => state.dailpad.timer);
  const runTimer = useSelector((state) => state.dailpad.runTimer);
  const callId = useSelector((state) => state.dailpad.callId);
  const campaignId = useSelector((state) => state.dailpad.campaignId);
  const showDailNextButton = useSelector(
    (state) => state.dailpad.showDailNextButton
  );
  const dailNextTimer = useSelector((state) => state.dailpad.dailNextTimer);
  const contactId = useSelector((state) => state.dailpad.contactId);
  const agentStatus = useSelector((state) => state.dailpad.agentStatus);
  const selectedDailer = useSelector((state) => state.dailpad.selectedDailer);
  const agentActiveCampaigns = useSelector(
    (state) => state.dailpad.agentActiveCampaigns
  );
  const selectedCampaign = useSelector(
    (state) => state.dailpad.selectedCampaign
  );
  const selectedCampaignDetails = useSelector(
    (state) => state.dailpad.selectedCampaignDetails
  );
  const showCampaignExitScreen = useSelector(
    (state) => state.dailpad.showCampaignExitScreen
  );
  const isCampaignInProgress = useSelector(
    (state) => state.dailpad.isCampaignInProgress
  );
  const campaignCompletionStat = useSelector(
    (state) => state.dailpad.campaignCompletionStat
  );
  const batchId = useSelector((state) => state.dailpad.batchId);
  const buttonRef = useRef(null);
  const {
    setIsOpen,
    setisMicOff,
    setIsPaused,
    setIsCallInProgress,
    setNumbers,
    setDestinationNumber,
    setDid,
    setCallType,
    setCallStatus,
    setUAList,
    setRTCSessionData,
    setDispositions,
    setTimer,
    setRunTimer,
    setCallId,
    setShowDailNextButton,
    setDailNextTimer,
    setAgentStatus,
    setDisposition,
    setSelectedDailer,
    setAgentActiveCampaigns,
    setSelectedCampaign,
    setSelectedCampaignDetails,
    setShowCampaignExitScreen,
    setIsCampaignInProgress,
    setDestinationName,
    setBatchId,
    setCampaignCompletionStat,
    setCampaignId
  } = props;

  const orgId = localStorage.getItem('doosra-biz-organisation-id');
  const isWebRTCEnabled = localStorage.getItem('is_webrtc_enabled');
  const sipDetails = localStorage.getItem('sip_details');
  const isBrowserCallEnabledForUser =
    isWebRTCEnabled === 'true' &&
    Object.keys(JSON.parse(sipDetails)).length > 0;
  const [loading, setLoading] = useState(false);
  const [selectedDisposition, setSelectedDisposition] = useState('');
  const [distanceToRight, setDistanceToRight] = useState('');
  const [showDispositionUpdatedMessage, setShowDispositionUpdatedMessage] =
    useState(false);
  const socket = useSocket();
  let options = {
    mediaConstraints: { audio: true, video: false },
    pcConfig: {},
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: tickAnimaiton,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const destroyWebsocketConnection = (domain) => {
    if (domain) {
      UAList.forEach(({ ua }) => {
        if (ua.hostport_params === domain) {
          ua.stop();
        }
      });
    } else {
      UAList.forEach(({ ua }) => {
        ua.stop();
      });
    }
    setUAList({ UAList: [] });
  };

  const resetValues = () => {
    setTimer({ timer: 0 });
    setIsCallInProgress({ isCallInProgress: false });
    setShowDailNextButton({ showDailNextButton: false });
    setDestinationName({ destinationName: '' });
    setDestinationNumber({ destinationNumber: '' });
    // setDid({ did: "" });
    setCallType({ callType: '' });
    setDisposition({ disposition: '' });
    setCallStatus({ callStatus: 'Ringing' });
    setDailNextTimer({ dailNextTimer: 30 });
    setContactId({ contactId: '' });
    setCallId({ callId: '' });
    setCampaignId({ campaignId: '' });
    setIsPaused({ isPaused: false });
    setisMicOff({ isMicOff: false });
  };

  const calculateDistance = () => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const distance = window.innerWidth - buttonRect.right;
      console.log('right', distance);
      setDistanceToRight(distance);
    }
  };

  const initiateCampaignCalls = (lastBatchId = "") => {
    const data = {};
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    data.campaignId = selectedCampaign;
    data.isPersistentCall = false;
    console.log(lastBatchId);
    if (showDailNextButton) {
      toast.error(
        'Please wait while previous call is being updated'
      );
      return;
    }

    let currentCampaignLeadsCalled = selectedCampaignDetails.leadsCalled;
    if (campaignCompletionStat) {
      if (selectedCampaignDetails.totalLeads === selectedCampaignDetails.leadsCalled) {
        currentCampaignLeadsCalled = parseInt(campaignCompletionStat.split("/")[0]);
      }
    }
    setLoading(true);
    if (
      selectedCampaignDetails.campaignType === 'static'
      && (selectedCampaignDetails.batchCallId || lastBatchId)
      && (selectedCampaignDetails.totalLeads > currentCampaignLeadsCalled)
      && selectedCampaignDetails.totalLeads
    ) {
      data.batchCallId = lastBatchId || selectedCampaignDetails.batchCallId;
      return POST(cloudCallUrl + 'cloud-call/resume-batch-call', data)
        .then((res) => {
          setBatchId({ batchId: data.batchCallId || "" });
          setIsCampaignInProgress({ isCampaignInProgress: true });
          setLoading(false);
          return true;
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error?.reason);
          setLoading(false);
          return false;
        });
    }

    console.log('the data is ', data);
    return POST(cloudCallUrl + 'cloud-call/initiate-call', data)
      .then((res) => {
        setBatchId({ batchId: res.data.response.callId });
        setIsCampaignInProgress({ isCampaignInProgress: true });
        setLoading(false);
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        setLoading(false);
        return false;
      });
  };

  useEffect(() => {
    let interval;
    if (runTimer) {
      interval = setInterval(() => {
        setTimer({});
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [timer, runTimer]);

  useEffect(() => {
    calculateDistance();
  }, []);

  const getAgentActiveCampaigns = () => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      userId: localStorage.getItem('doosra-biz-user-id'),
      campaignStatus: 'ACTIVE',
      pageNo: 1,
      pageSize: 100,
    };
    GET(cloudCallUrl + 'campaign/get-agent-campaigns', data)
      .then((res) => {
        setAgentActiveCampaigns({
          agentActiveCampaigns: res?.data?.response?.agentCampaignsObjs,
        });
        if (res?.data?.response?.agentCampaignsObjs.length > 0) {
          setSelectedCampaign({
            selectedCampaign: res?.data?.response?.agentCampaignsObjs[0]._id,
          });
          setSelectedCampaignDetails({
            selectedCampaignDetails: {
              totalLeads:
                res?.data?.response?.agentCampaignsObjs[0]?.batchCallObj
                  ?.totalLeads,
              leadsCalled:
                res?.data?.response?.agentCampaignsObjs[0]?.batchCallObj
                  ?.leadsCalled,
              answerRate: (
                (parseInt(
                  res?.data?.response?.agentCampaignsObjs[0]?.batchCallObj
                    ?.leadsCalled
                ) /
                  parseInt(
                    res?.data?.response?.agentCampaignsObjs[0]?.batchCallObj
                      ?.totalLeads
                  )) *
                100
              ).toFixed(2),
              campaignType:
                res?.data?.response?.agentCampaignsObjs[0]?.campaignType,
              callType: res?.data?.response?.agentCampaignsObjs[0]?.callType,
              batchCallId:
                res?.data?.response?.agentCampaignsObjs[0]?.batchCallObj
                  ?.batchCallId,
            },
          });
        } else {
          setSelectedCampaign({ selectedCampaign: '' });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const stopBatchCall = () => {
    var data = JSON.stringify({
      batchCallId: batchId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId: selectedCampaign,
      userId: localStorage.getItem('doosra-biz-user-id'),
    });
    return POST(cloudCallUrl + 'cloud-call/stop-batch-call', data)
      .then((res) => {
        console.log('true case');
        campaignExitScreen();
        callEnd();
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  useEffect(() => {
    if (
      selectedDailer === 'power' &&
      !showCampaignExitScreen &&
      !isCampaignInProgress
    ) {
      getAgentActiveCampaigns();
    }
  }, [selectedDailer]);
  useEffect(() => {
    console.log('Show dail next button interval');
    let dailNextInterval;
    if (showDailNextButton && isCampaignInProgress) {
      console.log('Show dail next button interval starting');
      dailNextInterval = setInterval(() => {
        setDailNextTimer({});
      }, 1000);
      setTimeout(() => {
        resetValues();
        clearInterval(dailNextInterval);
      }, 30000);
    }
    return () => {
      console.log('clearing the interval', dailNextInterval);
      clearInterval(dailNextInterval);
    };
  }, [showDailNextButton]);

  const getDomainName = (socketUrl) => {
    const startIndex = socketUrl.indexOf('//') + 2;
    const endIndex = socketUrl.indexOf('/', startIndex);
    const domainName = socketUrl.substring(startIndex, endIndex);
    return domainName;
  };

  const handleWebSocketConnect = (data) => {
    PUT(cloudCallUrl + 'websocket/update-user-websockets', {}, data)
      .then((res) => {
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
    return true;
  };
  const handleWebSocketDisConnect = (data) => {
    PUT(cloudCallUrl + 'websocket/update-user-websockets', {}, data)
      .then((res) => {
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };
  const sendSocketConnectionStatus = (socket, type) => {
    if (userRole === 'USER') {
      var data = JSON.stringify({
        userId: localStorage.getItem('doosra-biz-user-id'),
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        webSocket: socket,
        eventType: type,
      });
      if (type === 'connected') {
        handleWebSocketConnect(data);
      } else {
        handleWebSocketDisConnect(data);
      }
    }
  };
  useEffect(() => {
    UAList.forEach(({ ua: UA }) => {
      UA.on('disconnected', (e) => {
        UA.unregister({ all: true });
        const disconnectedSocket = e.socket._url;
        const domainName = getDomainName(disconnectedSocket);
        destroyWebsocketConnection(domainName);
        sendSocketConnectionStatus(domainName, 'disconnected');
      });
      UA.on('connected', (e) => {
        const connectedSocket = e.socket.socket._url;
        console.log('user registered successfully', connectedSocket);
        /*
          1. DONT USE CONNECTED EVENT TO SEND THE CONNECTION STATUS BECAUSE IT IS 
            TRIGGERED BEFORE THE WEBSOCKET CONNECTION IS ESTABLISHED COMPLETELY.
          2. USE REGISTERED EVENT TO SEND THE CONNECTION STATUS BECAUSE IT IS THE MAIN 
            EVENT THAT IS TRIGGERED AFTER THE WEBSOCKET CONNECTION IS ESTABLISHED COMPLETELY.
        */
        // const domainName = getDomainName(connectedSocket);
        // setTimeout(() => {
        //   sendSocketConnectionStatus(domainName, "connected")
        // }, 1000);
      });
      UA.on('registered', (e) => {
        console.log('user registered successfully');
        const connectedSocket = e.response.from._uri._host;
        setTimeout(() => {
          sendSocketConnectionStatus(connectedSocket, 'connected');
        }, 1000);
      });
      UA.on('registrationFailed', (e) => {
        console.log('user registration failed');
        // setIsAuthenticatedUser(false);
      });
      UA.on('unregistered', () => {
        console.log('user unregistration event');
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UAList]);
  useEffect(() => {
    const RTCSessions = [];
    UAList.forEach(({ ua: UA }) => {
      UA.on('newRTCSession', (e) => {
        console.log('Setting the newRTCSession');
        const newSession = e.session;
        /* START ICE CONFIG */
        let iceServers = [
          {
            urls: ['stun:stun.l.google.com:19302'],
            // eslint-disable-next-line no-dupe-keys
          },
          {
            urls: ['stun:stun.ekiga.net'],
          },
        ];
        const pbx = newSession._ua._configuration.hostport_params;
        iceServers.push(TURN_SERVER_CONFIG[pbx]);
        options.pcConfig['iceServers'] = iceServers;
        newSession.on('icecandidate', (candidate) => {
          console.log('iceCandidate gathered - ', candidate);
        });
        /* END ICE CONFIG */
        if (newSession.direction === 'incoming' && newSession.status === 4) {
          setRTCSessionData({ RTCSessionData: newSession });
          RTCSessions.push(newSession);
          newSession.on('accepted', function (event) {
            console.log(' Call Accepted ');
          });
          newSession.on('confirmed', function (event) {
            if (event.originator === 'remote') {
            }
          });
          // Call failed event
          newSession.on('failed', (event) => {
            console.log(' Call failed ');
            // resetValues();
          });
          // Call hungup event
          newSession.on('ended', (event) => {
            console.log(' Call hangup ');
            // resetValues();
          });
          // Answer Call
          if (newSession.status === 4) {
            newSession.answer(options);
            console.log('iceConnectionState - ', newSession);
          }
          // Audio connection
          newSession.connection.addEventListener('addstream', (e) => {
            var audio = new Audio();
            audio.srcObject = e.stream;
            audio.play();
          });
        }
      });
      return () => {
        UA.removeAllListeners('newRTCSession');
      };
    });
  }, [UAList]);

  useEffect(() => {
    if (socket) {
      socket.on('callConnected', (message, cb) => {
        console.log('ReceivedEvent callConnected', message);
        setCallStatus({ callStatus: 'Answered' });
        setRunTimer({ runTimer: true });
        setCallId({ callId: message.callId });
        cb({
          status: 'Acknowledged',
        });
      });

      socket.on('answeredCall', (message, cb) => {
        console.log('ReceivedEvent answeredCall', message);
        setCallStatus({ callStatus: 'Answered' });
        setRunTimer({ runTimer: true });
        setCallId({ callId: message.callId });
        cb({
          status: 'Acknowledged',
        });
      });

      socket.on('campaignCompleted', (message, cb) => {
        console.log('ReceivedEvent campaignCompleted', message);
        const campaignDetailsObj = selectedCampaignDetails;
        setSelectedCampaignDetails({
          selectedCampaignDetails: {
            ...campaignDetailsObj,
            isCampaignCompleted: true,
          },
        });
        setShowCampaignExitScreen({ showCampaignExitScreen: true });
        setIsCampaignInProgress({ isCampaignInProgress: false });
        cb({
          status: 'Acknowledged',
        });
      });

      socket.on('callEnd', (message, cb) => {
        console.log('ReceivedEvent callEnd', message);
        setCallStatus({ callStatus: 'Ended' });
        setIsOpen({ isOpen: true });
        setAgentStatus({ agentStatus: 'Online' });
        handleAgentStatus('Online');
        setRunTimer({ runTimer: false });
        setShowDailNextButton({ showDailNextButton: true });
        cb({
          status: 'Acknowledged',
        });
      });

      socket.on('refreshCampaignCallDetails', (message, cb) => {
        console.log('ReceivedEvent refreshCampaignCallDetails', message);
        setCallStatus({ callStatus: 'Ended' });
        setIsOpen({ isOpen: true });
        setAgentStatus({ agentStatus: 'Online' });
        handleAgentStatus('Online');
        setRunTimer({ runTimer: false });
        setIsCallInProgress({ isCallInProgress: false });
        if (message.isAnswered) {
          setShowDailNextButton({ showDailNextButton: true });
        } else {
          resetValues();
        }
        cb({
          status: 'Acknowledged',
        });
      });
      socket.on('singleCall', (message, cb) => {
        console.log('ReceivedEvent singleCall', message);
        const { data, callId } = message;
        setAgentStatus({ agentStatus: 'Busy' });
        handleAgentStatus('Busy');
        setDestinationName({ destinationName: data.name });
        setDestinationNumber({ destinationNumber: data.number });
        setCampaignCompletionStat({ campaignCompletionStat: data.position });
        setIsCallInProgress({ isCallInProgress: true });
        setDisposition({ disposition: "" });
        setSelectedDisposition("");
        setCallId({ callId })
        cb({
          status: 'Acknowledged',
        });
      });
      return () => {
        socket.off('callConnected');
        socket.off('answeredCall');
        socket.off('campaignCompleted');
        socket.off('callEnd');
        socket.off('refreshCampaignCallDetails');
        socket.off('singleCall');
      };
    }
  }, [socket]);
  useEffect(() => {
    GET(cloudCallUrl + `outbound/organisation/did-numbers`, {
      pageNo: 0,
      pageSize: 1000,
      organisation_id: orgId,
      active: true,
    })
      .then((res) => res.data.response)
      .then((res) => {
        setNumbers({ numbers: res.items });
        if (res.items.length >= 1) {
          setDid({ did: res.items[0]._id });
        } else {
          setDid({ did: '' });
        }
      })
      .catch((err) => console.log(err));
    getDispositions();
  }, []);

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    let formattedTime;
    if (hours > 0) {
      formattedTime = `${hours.toString().padStart(2, '0')}: ${minutes
        .toString()
        .padStart(2, '0')}: ${remainingSeconds.toString().padStart(2, '0')}`;
    } else {
      formattedTime = `${minutes
        .toString()
        .padStart(2, '0')}: ${remainingSeconds.toString().padStart(2, '0')}`;
    }
    return formattedTime;
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [callType, selectedCampaign, batchId]);

  const handleBeforeUnload = async (event) => {
    console.log('In the handle before', callType);
    event.preventDefault();
    event.returnValue = '';
    if (selectedCampaign && localStorage.getItem('doosra-biz-organisation-id')) {
      await stopBatchCall();
    }
    if (callType === 'VOIP') {
      callEnd();
    }
    resetValues();
    destroyWebsocketConnection();
  };

  const CampaignInfo = (props) => {
    return (
      <div className="campaign-info">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '24px',
          }}
        >
          <label className="Campaign-info-label">Campaign info</label>
        </div>
        <div>
          <div className="campaign-details">
            <label className="Campaign-details-key">Total leads</label>
            <label className="Campaign-details-value">
              {selectedCampaignDetails.totalLeads}
            </label>
          </div>
          <div className="campaign-details">
            <label className="Campaign-details-key">No of leads called</label>
            <label className="Campaign-details-value">
              {selectedCampaignDetails.leadsCalled}
            </label>
          </div>
          <div className="campaign-details">
            <label className="Campaign-details-key">Campaign completion</label>
            <label
              className="Campaign-details-value"
              style={{ color: '#07aa3f' }}
            >
              {selectedCampaignDetails.answerRate !== "NaN" ? selectedCampaignDetails.answerRate : 0}%
            </label>
          </div>
        </div>
      </div>
    );
  };
  const dailNextLead = () => {
    console.log('dail next lead button');
  };
  const handleWebsocketConnection = () => {
    if (agentStatus === 'Online' && isWebRTCEnabled === 'true') {
      GET(
        cloudCallUrl +
        `outbound/get-active-domains-organisation?organisationId=${orgId}`
      )
        .then((res) => res.data.response)
        .then((res) => {
          if (res?.length > 0) {
            const uaList = res.map((server) => {
              const socket = new JsSIP.WebSocketInterface(`wss://${server}/ws`);
              const uaConfig = {
                uri: `sip:${username}@${server}`,
                password: password,
                sockets: [socket],
                connection_recovery_min_interval: 5,
              };
              const ua = new JsSIP.UA(uaConfig);
              ua.start();
              return { ua: ua };
            });
            console.log(uaList);
            setUAList({ UAList: uaList });
            return uaList;
          }
        })
        .catch((err) => console.log(err));
    }
    if (agentStatus !== 'Online' && agentStatus !== 'Busy') {
      if (callType === 'VOIP') {
        callEnd();
      }
      resetValues();
      destroyWebsocketConnection();
      setIsOpen({ isOpen: false });
    }
  };

  useEffect(() => {
    handleWebsocketConnection();
  }, [agentStatus]);

  const changeCampaignDetails = (campaignId) => {
    const campaignObj = agentActiveCampaigns.filter(
      (campaign) => campaign._id === campaignId
    );
    console.log(campaignObj);
    setSelectedCampaignDetails({
      selectedCampaignDetails: {
        totalLeads: campaignObj[0]?.batchCallObj?.totalLeads,
        leadsCalled: campaignObj[0]?.batchCallObj?.leadsCalled,
        answerRate: (
          (parseInt(campaignObj[0]?.batchCallObj?.leadsCalled) /
            parseInt(campaignObj[0]?.batchCallObj?.totalLeads)) *
          100
        ).toFixed(2),
        campaignType: campaignObj[0]?.campaignType,
        callType: campaignObj[0]?.callType,
        batchCallId: campaignObj[0]?.batchCallObj?.batchCallId,
      },
    });
  };
  const getDispositions = () => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    return GET(cloudCallUrl + 'disposition', data)
      .then((res) => {
        setDispositions({ dispositions: res.data.response.dispositions });
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  useEffect(() => {
    if (showDispositionUpdatedMessage) {
      setTimeout(() => {
        setShowDispositionUpdatedMessage(false);
      }, 3000);
    }
  }, [showDispositionUpdatedMessage]);

  const updateDisposition = (callId, disposition) => {
    const data = {
      callId,
      disposition,
    };
    return PUT(
      cloudCallUrl + 'outbound/update-disposition-outbound-call',
      {},
      data
    )
      .then((res) => {
        setSelectedDisposition(disposition);
        setDisposition({ disposition });
        setShowDispositionUpdatedMessage(true);
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const addDispositionForConnectedCall = async (callId, callTag) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      callTag,
      callId
    }
    await POST(cloudCallUrl + 'campaign/add-call-tag', data).then((res) => {
      setSelectedDisposition(disposition);
      setDisposition({ disposition });
      setShowDispositionUpdatedMessage(true);
      return true;
    }).catch((err) => {
      toast.error(err?.response?.data?.error?.reason);
      return false;
    });
  }
  const micOn = () => {
    if (RTCSessionData) {
      RTCSessionData.unmute();
    }
  };

  const micOff = () => {
    if (RTCSessionData) {
      RTCSessionData.mute();
    }
  };

  const holdCall = () => {
    if (RTCSessionData) {
      RTCSessionData.hold();
    }
  };

  const unHoldCall = () => {
    if (RTCSessionData) {
      RTCSessionData.unhold();
    }
  };
  const callEnd = async () => {
    const requestData = {
      callId
    }
    await POST(cloudCallUrl +'cloud-call/agent-hangup', requestData);
  };

  const handleAgentStatus = async (eventKey) => {
    const breakReasonMap = {
      'Break': 'break',
      'Online': 'active',
      'Busy': 'busy'
    };

    let breakReason = breakReasonMap[eventKey] || 'offline';
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      agentId: localStorage.getItem('doosra-biz-user-id'),
      breakSwitch: "",
      breakReason
    }
    if (eventKey === 'Online') {
      data.breakSwitch = 'off';
    }
    else {
      data.breakSwitch = 'on';
    }
    console.log(data);
    await PUT(cloudCallUrl + 'metric/update-agent-break-time', {}, data)
      .then((res) => {
        return true;
      })
      .catch((err) => {
        return false;
      });
  }

  const campaignExitScreen = () => {
    console.log('show campaign exit ');
    setShowCampaignExitScreen({ showCampaignExitScreen: true });
    setIsCampaignInProgress({ isCampaignInProgress: false });
  };
  const initiateCall = () => {
    if (!contactId && !callId) {
      if (!destinationNumber) {
        toast.error('Destination Number is required');
        return;
      }
      if (
        destinationNumber.length !== 10 ||
        isNaN(destinationNumber) === true
      ) {
        toast.error('Please check the destination number');
        return;
      }
    }
    if (!did) {
      if (numbers.length === 0) {
        toast.error('No Caller ID available');
      } else {
        toast.error('Please select the did');
      }
      return;
    }
    if (!callType) {
      toast.error('Please select call Connectivity');
      return;
    }
    if (showDailNextButton) {
      toast.error(
        'Please wait while previous call is being updated'
      );
      return;
    }
    const callData = {
      number: destinationNumber,
      didId: did,
      contactId: null,
      userId: localStorage.getItem('doosra-biz-user-id'),
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      callType,
      contactId,
      callId
    };
    let apiRoute = 'cloud-call/initiate-outbound-call';
    console.log('campaign call', campaignId);
    if (campaignId) {
      callData.isSingleCall = true;
      callData.campaignId = campaignId;
      callData.singleCallId = callId;
      apiRoute = 'cloud-call/initiate-call';
    }
    POST(`${cloudCallUrl}${apiRoute}`, callData)
      .then((res) => {
        setCallId({
          callId: (res.data.response?.referenceId || res.data.response?.callId)
        });
        console.log('The call ils being connected');
        setIsCallInProgress({ isCallInProgress: true });
        setAgentStatus({ agentStatus: 'Busy' });
        handleAgentStatus('Busy');
      })
      .catch((err) => {
        console.log('The error is', err);
        toast.error(
          err.response?.data?.error?.reason ||
          'An error occured while initating the calls'
        );
      });
  };
  return (
    <>
      <div className="tooltip-container">
        {isCallInProgress && !isOpen ? (
          <DailerInProgress>
            <DailerInProgressInfo>
              {/* <DailerInProgressNumber style={{fontSize: "12px", top: "9px"}}>Single Call</DailerInProgressNumber> */}
              {/* <br></br> */}
              <DailerInProgressNumber style={{ fontSize: '10px', top: '14px' }}>
                {callStatus === 'Ended'
                  ? // ? `Dail Next In(${dailNextTimer})`
                  'Dail Next'
                  : `+91 ${destinationNumber}`}
              </DailerInProgressNumber>
            </DailerInProgressInfo>
            <DailerInProgressInfoTimer>
              {formatTime(timer)}
            </DailerInProgressInfoTimer>
            {callType === 'VOIP' ? (
              <DailerInProgressEndButton onClick={() => callEnd()}>
                <img src={callEndLogo} alt=""></img>
              </DailerInProgressEndButton>
            ) : null}
            <img
              style={{ position: 'absolute', left: '240px', top: '20px' }}
              src={circleDown}
              onClick={() => {
                setIsOpen({ isOpen: !isOpen });
              }}
              alt=""
            ></img>
          </DailerInProgress>
        ) : (
          <DailerButton
            ref={buttonRef}
            className={
              agentStatus === 'Online' || agentStatus === 'Busy'
                ? 'Dailer'
                : 'DailerInActive'
            }
            onClick={() => {
              if (agentStatus === 'Online' || agentStatus === 'Busy') {
                setIsOpen({ isOpen: !isOpen });
              }
            }}
          >
            <img src={DailPadIcon} width={28} height={28} />
          </DailerButton>
        )}
        <div className="tooltip-text left" data-tip-position="left">
          Dialer
        </div>
      </div>

      <div
        style={{
          background: 'white',
          display: isOpen ? 'block' : 'none',
          width: '300px',
          height:
            showCampaignExitScreen && selectedDailer === 'power'
              ? '290px'
              : selectedDailer === 'power' && isCallInProgress === true
                ? '445px'
                : '430px',
          borderRadius: '5px',
          boxShadow: '0px 0px 4px 0px black',
          position: 'absolute',
          right: `${distanceToRight}px`,
          top: '15%',
          zIndex: '10',
          borderRadius: '12px',
        }}
      >
        <GradientDiv>
          <div
            style={{
              position: 'absolute',
              marginTop: '24px',
              marginLeft: '24px',
            }}
          >
            <img src={AlohaaLogo} width={24} height={20}></img>
          </div>
          <img
            src={ExpandCircleDown}
            onClick={() => {
              if (agentStatus === 'Online' || agentStatus === 'Busy') {
                setIsOpen({ isOpen: !isOpen });
              }
            }}
            style={{ position: 'absolute', right: '20px', top: '20px' }}
          ></img>
          <p
            onClick={() => {
              if (isCampaignInProgress) {
                toast.error('There is a active campaign');
                return;
              }
              setSelectedDailer({ selectedDailer: 'solo' });
            }}
            style={{
              position: 'absolute',
              top: '60px',
              left: '27px',
              marginBottom: '0px',
              paddingBottom: '14px',
              borderBottom: selectedDailer === 'solo' ? '3px solid white' : '',
              color: 'white',
              fontSize: '14px',
              fontWeight: 700,
              cursor: 'pointer',
            }}
          >
            Solo dialer{' '}
          </p>
          {localStorage.getItem('is_campaigns_v2_enabled') === 'true' && <p
            onClick={() => {
              if (isCallInProgress) {
                toast.error('There is a active call');
                return;
              }
              setSelectedDailer({ selectedDailer: 'power' });
            }}
            style={{
              position: 'absolute',
              top: '60px',
              right: '24px',
              marginBottom: '0px',
              paddingBottom: '14px',
              borderBottom: selectedDailer === 'power' ? '3px solid white' : '',
              color: 'white',
              fontSize: '14px',
              fontWeight: 700,
              cursor: 'pointer',
            }}
          >
            Power dialer{' '}
          </p>}

        </GradientDiv>
        {selectedDailer === 'solo' ? (
          isCallInProgress ? (
            <DailerDiv theme={themeType}>
              <div style={{ display: 'flex', paddingTop: '16px' }}>
                <label
                  style={{
                    marginLeft: '16px',
                    color: themeType === 'light' ? 'black' : 'white',
                  }}
                >
                  {destinationName || 'Single Call'}
                </label>
                <label
                  style={{
                    marginLeft: 'auto',
                    marginRight: '10px',
                    color: themeType === 'light' ? 'black' : 'white',
                  }}
                >
                  {formatTime(timer)}
                </label>
              </div>
              <div style={{ display: 'flex' }}>
                <label
                  style={{
                    marginLeft: '16px',
                    color: themeType === 'light' ? 'black' : 'white',
                  }}
                >{`+91 ${destinationNumber}`}</label>
                <label
                  style={{
                    marginLeft: 'auto',
                    marginRight: '10px',
                    fontWeight: 700,
                    color: showDailNextButton ? 'red' : 'green',
                  }}
                >
                  {callStatus}
                </label>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="tooltip-container">
                  <div
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '50%',
                      background:
                        callStatus === 'Ringing' ||
                          callStatus === 'Ended' ||
                          callType === 'PSTN'
                          ? '#D9D9D9'
                          : isMicOff
                            ? 'red'
                            : 'white',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: `1px solid ${callStatus === 'Ringing' ||
                        callStatus === 'Ended' ||
                        callType === 'PSTN'
                        ? '#D9D9D9'
                        : 'red'
                        }`,
                      cursor: callType === 'PSTN' ? 'not-allowed' : 'pointer',
                      marginRight: '15px',
                    }}
                    onClick={() => {
                      if (callStatus !== 'Ringing') {
                        if (isMicOff) {
                          micOn();
                        } else {
                          micOff();
                        }
                        setisMicOff({ isMicOff: !isMicOff });
                      }
                    }}
                  >
                    <img
                      src={
                        callStatus === 'Ringing' ||
                          callStatus === 'Ended' ||
                          callType === 'PSTN'
                          ? GreyMic
                          : isMicOff
                            ? MicOff
                            : Mic
                      }
                    ></img>
                  </div>
                  {(callStatus === 'Answered' || callStatus === 'Ringing') &&
                    callType === 'PSTN' && (
                      <div
                        className="tooltip-text left"
                        data-tip-position="left"
                      >
                        Use your phone to manage GSM calls.{' '}
                      </div>
                    )}
                </div>

                <div className="tooltip-container">
                  <div
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '50%',
                      background:
                        callStatus === 'Ringing' ||
                          callStatus === 'Ended' ||
                          callType === 'PSTN'
                          ? '#D9D9D9'
                          : isPaused
                            ? 'red'
                            : 'white',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: `1px solid ${callStatus === 'Ringing' ||
                        callStatus === 'Ended' ||
                        callType === 'PSTN'
                        ? '#D9D9D9'
                        : 'red'
                        }`,
                      cursor: callType === 'PSTN' ? 'not-allowed' : 'pointer',
                      marginLeft: '15px',
                    }}
                    onClick={() => {
                      if (callStatus !== 'Ringing') {
                        if (isPaused) {
                          unHoldCall();
                        } else {
                          holdCall();
                        }
                        setIsPaused({ isPaused: !isPaused });
                      }
                    }}
                  >
                    <img
                      src={
                        callStatus === 'Ringing' ||
                          callStatus === 'Ended' ||
                          callType === 'PSTN'
                          ? GreyPause
                          : isPaused
                            ? Play
                            : Pause
                      }
                    ></img>
                  </div>
                  {(callStatus === 'Answered' || callStatus === 'Ringing') &&
                    callType === 'PSTN' && (
                      <div
                        className="tooltip-text left"
                        data-tip-position="left"
                      >
                        Use your phone to manage GSM calls.{' '}
                      </div>
                    )}
                </div>
              </div>
              <p
                style={{
                  marginTop: '15px',
                  marginRight: '135px',
                  color: 'black',
                  marginBottom: '12px',
                  borderRadius: '5px',
                  color: themeType === 'light' ? 'black' : 'white',
                }}
              >
                Select Disposition
              </p>
              <select
                onChange={(e) => campaignId ? addDispositionForConnectedCall(callId, e.target.value) : updateDisposition(callId, e.target.value)}
                style={{
                  height: '36px',
                  width: '260px',
                  display: 'block',
                  margin: '0px auto 15px auto',
                  color: themeType === 'light' ? 'black' : 'white',
                  background: themeType === 'light' ? 'white' : '#282828',
                  borderRadius: '5px',
                }}
              >
                <option value="" selected={true}>
                  Select disposition
                </option>
                {dispositions.map((i) => (
                  <option
                    key={i._id}
                    value={i.dispositon}
                    selected={disposition === i.dispositon ? true : false}
                  >
                    {i.dispositon}
                  </option>
                ))}
              </select>
              {showDispositionUpdatedMessage ? (
                <label
                  style={{
                    position: 'absolute',
                    left: '70px',
                    fontSize: '12px',
                    color: 'green',
                  }}
                >
                  <img className="disposition-updated" src={GreenTick} />
                  Disposition Updated
                </label>
              ) : null}
              {showDailNextButton ? (
                <DailNextButton onClick={() => resetValues()}>
                  <label
                    style={{
                      color: '#07AA3F',
                      marginBottom: '0px',
                      cursor: 'pointer',
                    }}
                  >
                    {/* Dail Next In ({dailNextTimer}) */}
                    Dial Next
                  </label>
                </DailNextButton>
              ) : (
                <EndButton
                  style={{
                    background: callType === 'PSTN' ? 'lightgrey' : 'red',
                    cursor: callType === 'PSTN' ? 'not-allowed' : 'pointer',
                  }}
                  onClick={() => callEnd()}
                >
                  <img src={HangupLogo}></img>
                </EndButton>
              )}
            </DailerDiv>
          ) : (
            <DailerDiv theme={themeType}>
              <p
                style={{
                  margin: '0px 140px 10px 16px',
                  color: themeType === 'light' ? 'black' : 'white',
                  fontWeight: 400,
                  paddingTop: '15px',
                }}
              >
                Enter number
              </p>
              <input
                type={'text'}
                value={destinationNumber}
                style={{
                  height: '36px',
                  width: '216px',
                  display: 'block',
                  margin: '0px auto',
                  borderRadius: '5px',
                  border: '1px solid #D9D9D9',
                  marginTop: '5px',
                  color: themeType === 'light' ? 'black' : 'white',
                  background: themeType === 'light' ? 'white' : '#282828',
                }}
                onChange={(e) => {
                  if (callId) {
                    setCallId({ callId: '' });
                  }
                  if (contactId) {
                    setContactId({ contactId: '' });
                  }
                  if (campaignId) {
                    setCampaignId({ campaignId: '' });
                  }
                  const destinationNo = e.target.value.replace(/[^0-9#]/g, '');
                  setDestinationNumber({ destinationNumber: destinationNo });
                }}
              ></input>
              <p
                style={{
                  margin: '15px 135px 10px 16px',
                  color: 'black',
                  fontWeight: 400,
                  color: themeType === 'light' ? 'black' : 'white',
                }}
              >
                Select Caller ID
              </p>
              <select
                style={{
                  height: '36px',
                  width: '216px',
                  display: 'block',
                  margin: '0px auto',
                  marginTop: '5px',
                  borderRadius: '5px',
                  color: themeType === 'light' ? 'black' : 'white',
                  background: themeType === 'light' ? 'white' : '#282828',
                }}
                disabled={
                  numbers.length === 1 || numbers.length === 0 ? true : false
                }
                onChange={(e) => {
                  setDid({ did: e.target.value });
                }}
              >
                <>
                  {numbers.length === 0 && (
                    <option value="" selected={did === '' ? true : false}>
                      No Caller ID available
                    </option>
                  )}
                  {numbers.length === 1 &&
                    numbers.map((number) => (
                      <option key={number._id} value={number._id}>
                        {number.virtualNumber}
                      </option>
                    ))}
                  {numbers.length > 1 &&
                    numbers.map((number) => (
                      <option
                        key={number._id}
                        value={number._id}
                        selected={number._id === did}
                      >
                        {number.virtualNumber}
                      </option>
                    ))}
                </>
              </select>
              <p
                style={{
                  margin:
                    localStorage.getItem('webrtc_enabled') !== 'true' ||
                      !isBrowserCallEnabledForUser
                      ? '15px 110px 2px 16px'
                      : '15px 125px 2px 16px',
                  color: themeType === 'light' ? 'black' : 'white',
                }}
              >
                Call Connectivity
                {(localStorage.getItem('webrtc_enabled') !== 'true' ||
                  !isBrowserCallEnabledForUser) && (
                    <div className="tooltip-container">
                      <img src={Help} style={{ marginLeft: '4px' }}></img>
                      <div className="tooltip-text left" data-tip-position="left">
                        Upgrade to get access to broswer calling
                      </div>
                    </div>
                  )}
              </p>
              <div>
                <label
                  style={{
                    marginTop: '10px',
                    marginRight: '40px',
                    color: 'black',
                    marginBottom: '0px',
                    fontSize: '16px',
                    color: themeType === 'light' ? 'black' : 'white',
                  }}
                >
                  <input
                    type="radio"
                    value="PSTN"
                    checked={callType === 'PSTN'}
                    disabled={
                      !(localStorage.getItem('pstn_enabled') === 'true')
                    }
                    onChange={() => {
                      setCallType({ callType: 'PSTN' });
                    }}
                  />
                  &nbsp; GSM
                </label>
                <label
                  style={{
                    color: 'black',
                    marginRight: '70px',
                    marginBottom: '10px',
                    fontSize: '16px',
                    color: themeType === 'light' ? 'black' : 'white',
                  }}
                >
                  <input
                    type="radio"
                    value="VOIP"
                    checked={callType === 'VOIP'}
                    disabled={
                      !(localStorage.getItem('webrtc_enabled') === 'true') ||
                      !isBrowserCallEnabledForUser
                    }
                    onChange={() => {
                      setCallType({ callType: 'VOIP' });
                    }}
                  />
                  &nbsp; Browser
                </label>
              </div>
              <DailButton
                style={{ margin: '15px auto 0px auto' }}
                onClick={() => initiateCall()}
              >
                <img src={phoneWhite}></img>
              </DailButton>
            </DailerDiv>
          )
        ) : showCampaignExitScreen ? (
          <ExitCampaignDiv theme={themeType}>
            <span className="Campaign-exited-label">Campaign exited</span>
            <span className="Customer-outreach-label">
              Customer outreach campaign {campaignCompletionStat} called
            </span>
            {!selectedCampaignDetails.isCampaignCompleted ? (
              <ResumeCallingButton
                onClick={() => {
                  if (showDailNextButton) {
                    toast.error(
                      'Please wait while previous call is being updated'
                    );
                    return;
                  } else {
                    setShowCampaignExitScreen({
                      showCampaignExitScreen: false,
                    });
                    initiateCampaignCalls(batchId);
                  }
                }}
              >
                <label
                  style={{
                    color: '#07AA3F',
                    marginBottom: '0px',
                    cursor: 'pointer',
                  }}
                >
                  Resume calling
                </label>
              </ResumeCallingButton>
            ) : null}

            <span
              onClick={() => {
                setShowCampaignExitScreen({ showCampaignExitScreen: false });
                getAgentActiveCampaigns();
                setIsCampaignInProgress({ isCampaignInProgress: false });
              }}
              className="Choose-another-campaign-label"
            >
              Choose another campaign
            </span>
          </ExitCampaignDiv>
        ) : isCampaignInProgress ? (
          <DailerDiv theme={themeType}>
            <div style={{ display: 'flex', paddingTop: '16px' }}>
              <label
                style={{
                  marginLeft: '16px',
                  color: themeType === 'light' ? 'black' : 'white',
                }}
              >
                {destinationName || ''}
              </label>
              <label
                style={{
                  marginLeft: 'auto',
                  marginRight: '10px',
                  color: themeType === 'light' ? 'black' : 'white',
                }}
              >
                {formatTime(timer)}
              </label>
            </div>
            <div style={{ display: 'flex' }}>
              <label
                style={{
                  marginLeft: '16px',
                  color: themeType === 'light' ? 'black' : 'white',
                }}
              >{`+91 ${destinationNumber}`}</label>
              <label
                style={{
                  marginLeft: 'auto',
                  marginRight: '10px',
                  fontWeight: 700,
                  color: showDailNextButton ? 'red' : 'green',
                }}
              >
                {callStatus}
              </label>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className="tooltip-container">
                <div
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    background:
                      callStatus === 'Ringing' ||
                        callStatus === 'Ended' ||
                        callType === 'PSTN' ||
                        (selectedCampaignDetails &&
                          selectedCampaignDetails.callType === 'PSTN')
                        ? '#D9D9D9'
                        : isMicOff
                          ? 'red'
                          : 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: `1px solid ${callStatus === 'Ringing' ||
                      callStatus === 'Ended' ||
                      callType === 'PSTN' ||
                      (selectedCampaignDetails &&
                        selectedCampaignDetails.callType === 'PSTN')
                      ? '#D9D9D9'
                      : 'red'
                      }`,
                    cursor:
                      callType === 'PSTN' ||
                        (selectedCampaignDetails &&
                          selectedCampaignDetails.callType === 'PSTN')
                        ? 'not-allowed'
                        : 'pointer',
                    marginRight: '15px',
                  }}
                  onClick={() => {
                    if (callStatus !== 'Ringing') {
                      if (isMicOff) {
                        micOn();
                      } else {
                        micOff();
                      }
                      setisMicOff({ isMicOff: !isMicOff });
                    }
                  }}
                >
                  <img
                    src={
                      callStatus === 'Ringing' ||
                        callStatus === 'Ended' ||
                        callType === 'PSTN' ||
                        (selectedCampaignDetails &&
                          selectedCampaignDetails.callType === 'PSTN')
                        ? GreyMic
                        : isMicOff
                          ? MicOff
                          : Mic
                    }
                  ></img>
                </div>
                {(callStatus === 'Answered' || callStatus === 'Ringing') &&
                  (callType === 'PSTN' ||
                    (selectedCampaignDetails &&
                      selectedCampaignDetails.callType === 'PSTN')) && (
                    <div className="tooltip-text left" data-tip-position="left">
                      Use your phone to manage GSM calls.{' '}
                    </div>
                  )}
              </div>

              <div className="tooltip-container">
                <div
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    background:
                      callStatus === 'Ringing' ||
                        callStatus === 'Ended' ||
                        callType === 'PSTN' ||
                        (selectedCampaignDetails &&
                          selectedCampaignDetails.callType === 'PSTN')
                        ? '#D9D9D9'
                        : isPaused
                          ? 'red'
                          : 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: `1px solid ${callStatus === 'Ringing' ||
                      callStatus === 'Ended' ||
                      callType === 'PSTN' ||
                      (selectedCampaignDetails &&
                        selectedCampaignDetails.callType === 'PSTN')
                      ? '#D9D9D9'
                      : 'red'
                      }`,
                    cursor:
                      callType === 'PSTN' ||
                        (selectedCampaignDetails &&
                          selectedCampaignDetails.callType === 'PSTN')
                        ? 'not-allowed'
                        : 'pointer',
                    marginLeft: '15px',
                  }}
                  onClick={() => {
                    if (callStatus !== 'Ringing') {
                      if (isPaused) {
                        unHoldCall();
                      } else {
                        holdCall();
                      }
                      setIsPaused({ isPaused: !isPaused });
                    }
                  }}
                >
                  <img
                    src={
                      callStatus === 'Ringing' ||
                        callStatus === 'Ended' ||
                        callType === 'PSTN' ||
                        (selectedCampaignDetails &&
                          selectedCampaignDetails.callType === 'PSTN')
                        ? GreyPause
                        : isPaused
                          ? Play
                          : Pause
                    }
                  ></img>
                </div>
                {(callStatus === 'Answered' || callStatus === 'Ringing') &&
                  (callType === 'PSTN' ||
                    (selectedCampaignDetails &&
                      selectedCampaignDetails.callType === 'PSTN')) && (
                    <div className="tooltip-text left" data-tip-position="left">
                      Use your phone to manage GSM calls.{' '}
                    </div>
                  )}
              </div>
            </div>
            {/* <p
              style={{
                marginTop: '15px',
                marginRight: '135px',
                color: 'black',
                marginBottom: '12px',
                borderRadius: '5px',
                color: themeType === 'light' ? 'black' : 'white',
              }}
            >
              Select Disposition
            </p> */}
            {
              callId && <select
                onChange={(e) => addDispositionForConnectedCall(callId, e.target.value)}
                style={{
                  height: '36px',
                  width: '260px',
                  display: 'block',
                  margin: '24px auto 15px auto',
                  color: themeType === 'light' ? 'black' : 'white',
                  background: themeType === 'light' ? 'white' : '#282828',
                  borderRadius: '5px',
                }}
              >
                <option value="" selected={true}>
                  Select disposition
                </option>
                {dispositions.map((i) => (
                  <option
                    key={i._id}
                    value={i.dispositon}
                    selected={disposition === i.dispositon ? true : false}
                  >
                    {i.dispositon}
                  </option>
                ))}
              </select>
            }
            {showDispositionUpdatedMessage ? (
              <label
                style={{
                  position: 'absolute',
                  left: '70px',
                  fontSize: '12px',
                  color: 'green',
                }}
              >
                <img className="disposition-updated" src={GreenTick} />
                Disposition Updated
              </label>
            ) : null}
            {showDailNextButton ? (
              <DailNextButton type="power" onClick={() => dailNextLead()}>
                <label
                  style={{
                    color: '#07AA3F',
                    marginBottom: '0px',
                    cursor: 'pointer',
                  }}
                >
                  Next Call In ({dailNextTimer})
                </label>
              </DailNextButton>
            ) : (
              <EndButton
                type="power"
                style={{
                  background:
                    callType === 'PSTN' ||
                      (selectedCampaignDetails &&
                        selectedCampaignDetails.callType === 'PSTN')
                      ? 'lightgrey'
                      : 'red',
                  cursor:
                    callType === 'PSTN' ||
                      (selectedCampaignDetails &&
                        selectedCampaignDetails.callType === 'PSTN')
                      ? 'not-allowed'
                      : 'pointer',
                }}
                onClick={() => callEnd()}
              >
                <img src={HangupLogo}></img>
              </EndButton>
            )}
            <div>
              <p
                className="Exit-campaign"
                onClick={() => {
                  setIsCallInProgress({ isCallInProgress: false });
                  stopBatchCall();
                }}
              >
                Exit campaign
              </p>
              <p className="campaign-completion-text">
                Customer outreach campaign {campaignCompletionStat} called
              </p>
            </div>
          </DailerDiv>
        ) : (
          <DailerDiv theme={themeType}>
            <p
              style={{
                margin: '15px 135px 10px 16px',
                color: 'black',
                fontWeight: 400,
                color: themeType === 'light' ? 'black' : 'white',
              }}
            >
              Select campaign
            </p>
            <select
              style={{
                height: '36px',
                width: '240px',
                display: 'block',
                margin: '0px auto',
                marginTop: '5px',
                borderRadius: '5px',
                color: themeType === 'light' ? 'black' : 'white',
                background: themeType === 'light' ? 'white' : '#282828',
              }}
              disabled={agentActiveCampaigns.length === 0 ? true : false}
              onChange={(e) => {
                setSelectedCampaign({ selectedCampaign: e.target.value });
                changeCampaignDetails(e.target.value);
              }}
            >
              {agentActiveCampaigns.length === 0 && (
                <option key={''} value={''} selected={true}>
                  No Active Campaigns
                </option>
              )}
              {agentActiveCampaigns.length > 0 &&
                agentActiveCampaigns.map((campaign) => (
                  <option
                    key={campaign._id}
                    value={campaign._id}
                    selected={campaign._id === selectedCampaign}
                  >
                    {campaign.campaignName}
                  </option>
                ))}
            </select>
            {selectedCampaign ? <CampaignInfo /> : null}
            <DailButton
              style={{
                margin: '15px 35px 0px 35px',
                position: 'absolute',
                bottom: '16px',
                cursor:
                  (agentActiveCampaigns.length === 0 || loading) ? 'not-allowed' : 'pointer',
                background:
                  (agentActiveCampaigns.length === 0 || loading)
                    ? 'grey'
                    : 'var(--Alohaa-Green, #07aa3f)',
              }}
              onClick={() => {
                if (!loading && agentActiveCampaigns.length > 0) {
                  initiateCampaignCalls();
                }
              }}
            >
              <img src={phoneWhite}></img>
            </DailButton>
          </DailerDiv>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default commonStyle(
  connect(mapStateToProps, {
    setIsOpen,
    setisMicOff,
    setIsPaused,
    setIsCallInProgress,
    setNumbers,
    setDestinationNumber,
    setDid,
    setCallType,
    setCallStatus,
    setUAList,
    setRTCSessionData,
    setDispositions,
    setTimer,
    setRunTimer,
    setCallId,
    setShowDailNextButton,
    setDailNextTimer,
    setContactId,
    setAgentStatus,
    setDisposition,
    setSelectedDailer,
    setAgentActiveCampaigns,
    setSelectedCampaign,
    setSelectedCampaignDetails,
    setShowCampaignExitScreen,
    setIsCampaignInProgress,
    setDestinationName,
    setBatchId,
    setCampaignCompletionStat,
    setCampaignId
  })(DailPad)
);
