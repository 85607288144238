import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { commonStyle } from '../../../assets/css/css';

import '../../../assets/css/Content.css';
import { GET } from '../../../services/HttpRequests';
import AudioPlayer from '../components/AudioPlayer';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Sidebar from '../../../common/component/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import DataTable from '../../../common/component/table/DataTable';
import ViewContactPopup from '../../contacts/component/ViewContactPopup';
import AddContactNotesTagsPopup from '../../contacts/component/AddContactNotesTagsPopup';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import TagIcon from '../../../assets/images/tag.png';
import AICallAnalysisPopup from "../../../common/component/callAiPopup/index";
import {
  SEARCH_CALL_LOG_BUTTON,
  INCOMING_TAB,
  OUTGOING_TAB,
  DID_CALL_BACK_TAB,
  ADMIN_SEARCH_CALL_LOG_BUTTON,
  ADMIN_INCOMING_TAB,
  ADMIN_OUTGOING_TAB,
  ADMIN_DID_CALL_BACK_TAB,
  ADMIN_BLACKLIST_TAB,
} from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';
import PlanExpired from '../../../common/component/plan-expired/PlanExpired';

const outgoingCallsUrl = process.env.REACT_APP_OUTGOING_CALLS_API;
const incomingCallsUrl = process.env.REACT_APP_INCOMING_CALLS_API;

// const organisationId = localStorage.getItem('doosra-biz-organisation-id');
// const isUnansweredEnabled = [
//   "0c82dcd0-dc2b-11ec-8f79-1519cf62ab83", "259150fd-6c5a-4886-9eb7-901690b9f024",
//   "e6cabbf5-5c0d-4f21-a20e-e2cceb18f3ee", "d9e43a4a-8201-425d-89ff-12e571e45a67",
//   "1f117d72-360c-4c19-a08c-ef5c97e9961f", "421d68a1-8f6b-4741-970a-068726647ea5",
//   "afc9311a-801e-42a5-b4b8-f1c669317b5c", "c1f49fa7-4997-47c5-b6dd-b395f7742bc1",
//   "89311a60-ee25-11ec-880a-9391784445de"
// ].includes(organisationId)

class CallLogsPage extends Component {
  constructor() {
    super();
    this.state = {
      count: 0,
      outgoingCallLogCount: 0,
      didCallBackCount: 0,
      callBacksCount: 0,
      blackListCount: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 15,
      callList: [],
      loading: false,
      searchData: {},
    };
  }

  componentDidMount() {
    this.getFreshList();
    this.getOutgoingCallLogCount();
    // this.getDIDCallBackCount();
    // this.getCallBlackListCount();
  }

  getFreshList = () => {
    this.setState({ loading: true });
    let data = {
      pageNo: this.state.currentPage,
      pageSize: this.state.pageSize,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    };
    console.log('getFreshList', { data });
    GET(`${incomingCallsUrl}v1/organisations/calls`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        let count = Math.ceil(res.payload.count / this.state.pageSize);
        if (count === 0) count = 1;
        const data = res.payload.docs;
        data.map((item) => {
          if (item.notesObj) {
            const notes = item.notesObj.map((obj) => obj.note);
            if (notes && notes.length > 0) {
              item.notes = notes.join(', ');
            }
          }
          if (item.tagsObj) {
            item.tags = item.tagsObj.map((obj) => obj.tag);
          }
          return item;
        });
        this.setState({
          count: res.payload.count,
          callList: data,
          totalPages: count,
          pageSize: res.pageSize,
          loading: false,
          searchData: {},
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  seachHandler = (searchitem) => {
    const userRole = localStorage.getItem('doosra-biz-user-role');
    logPostHogEvent(
      userRole === 'ADMIN'
        ? ADMIN_SEARCH_CALL_LOG_BUTTON
        : SEARCH_CALL_LOG_BUTTON
    );
    searchitem.pageNo = 1;
    searchitem.pageSize = this.state.pageSize;
    searchitem.organisation_id = localStorage.getItem(
      'doosra-biz-organisation-id'
    );
    console.log('search handler', { searchitem });

    GET(`${incomingCallsUrl}v1/organisations/calls`, searchitem)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        let count = Math.ceil(res.payload.count / this.state.pageSize);
        if (count === 0) count = 1;
        const data = res.payload.docs;
        data.map((item) => {
          if (item.notesObj) {
            const notes = item.notesObj.map((obj) => obj.note);
            if (notes && notes.length > 0) {
              item.notes = notes.join(', ');
            }
          }
          if (item.tagsObj) {
            item.tags = item.tagsObj.map((obj) => obj.tag);
          }
          return item;
        });
        this.setState({
          callList: data,
          count: res.payload.count,
          totalPages: count,
          pageSize: res.pageSize,
          searchData: searchitem,
        });
      })
      .catch((err) => {
        if (err) {
          this.setState({ loading: false });
        }
      });
  };

  fetchPageData = (value) => {
    let data = {
      pageNo: value,
      pageSize: this.state.pageSize,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    };
    console.log('fetchPageData', { data, search: this.state.searchData });

    data = {
      ...this.state.searchData,
      ...data,
    };
    console.log('fetchPageData', { data });
    GET(`${incomingCallsUrl}v1/organisations/calls`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        const data = res.payload.docs;
        data.map((item) => {
          if (item.notesObj) {
            const notes = item.notesObj.map((obj) => obj.note);
            if (notes && notes.length > 0) {
              item.notes = notes.join(', ');
            }
          }
          if (item.tagsObj) {
            item.tags = item.tagsObj.map((obj) => obj.tag);
          }
          return item;
        });
        this.setState({
          count: res.payload.count,
          callList: data,
          currentPage: value,
          pageSize: res.pageSize,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getOutgoingCallLogCount = () => {
    this.setState({ loading: true });
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    GET(`${outgoingCallsUrl}v1/organisations/outbound-call/call-log/list`, {
      pageNo: 1,
      pageSize: 10,
      organisationId: orgId,
    })
      .then((res) => res.data.response)
      .then((res) =>
        this.setState({
          outgoingCallLogCount: res.metadata.total,
          loading: false,
        })
      )
      .catch((err) => null);
  };
  getDIDCallBackCount = () => {
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    GET(`${incomingCallsUrl}v1/organisations/did-callback-list`, {
      pageNo: 1,
      pageSize: 10,
      organisationId: orgId,
    })
      .then((res) => res.data.response)
      .then((res) => this.setState({ didCallBackCount: res.payload.count }))
      .catch((err) => console.log(err));
  };

  getCallBlackListCount = () => {
    GET(`${incomingCallsUrl}v1/organisations/black-list`, {
      pageNo: 1,
      pageSize: 10,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => res.data.response)
      .then((res) => this.setState({ blackListCount: res.payload.count }))
      .catch((err) => console.log(err));
  };

  trimNumber = (number) => {
    if (number && number.length === 12) return number.substring(2);
    return number;
  };

  render() {
    const { callList } = this.state;
    const userRole = localStorage.getItem('doosra-biz-user-role');
    let isFreeTrail = localStorage.getItem('is_free_trial_enabled');
    isFreeTrail = isFreeTrail ? (isFreeTrail === 'true' ? true : false) : false;
    const {
      callForwardingStatus,
      isOutboundCallEnabled,
      isOrgOutboundCallEnabled,
      isUnansweredAgentEnabled,
    } = this.props;

    // let displayData;
    const tabs = [];
    if (callForwardingStatus)
      tabs.push({
        title: 'Incoming',
        subtitle: `${this.state.count}`,
        onClick: () => {
          logPostHogEvent(
            userRole === 'ADMIN' ? ADMIN_INCOMING_TAB : INCOMING_TAB
          );
          this.props.history.push('/call-logs');
        },
        active: true,
      });

    if (
      (userRole === 'ADMIN' && isOrgOutboundCallEnabled) ||
      (userRole === 'USER' && isOutboundCallEnabled && isOrgOutboundCallEnabled)
    ) {
      // if (!isFreeTrail) {
      //   tabs.push({
      //     title: 'Callback',
      //     subtitle: `${this.state.didCallBackCount}`,
      //     onClick: () => {
      //       logPostHogEvent(
      //         userRole === 'ADMIN' ? ADMIN_DID_CALL_BACK_TAB : DID_CALL_BACK_TAB
      //       );
      //       this.props.history.push('/did-call-back');
      //     },
      //   });
      // }
      tabs.push({
        title: 'Outgoing',
        subtitle: `${this.state.outgoingCallLogCount}`,
        onClick: () => {
          logPostHogEvent(
            userRole === 'ADMIN' ? ADMIN_OUTGOING_TAB : OUTGOING_TAB
          );
          this.props.history.push('/outgoing-call-logs');
        },
      });
    }

    // if (userRole === 'ADMIN') {
    //   tabs.push({
    //     title: 'BlackList',
    //     subtitle: `${this.state.blackListCount}`,
    //     onClick: () => {
    //       logPostHogEvent(ADMIN_BLACKLIST_TAB);
    //       this.props.history.push('/black-list');
    //     },
    //   });
    // }

    const showDtmfCodes = (ivrDetails) => {
      if (ivrDetails && ivrDetails.length > 0) {
        ivrDetails = ivrDetails.filter((obj) => obj.dtmf_code !== 'timeout');
        const dtmfcodes = ivrDetails.map((obj) => obj.dtmf_code);
        return dtmfcodes && dtmfcodes.length > 0 ? dtmfcodes.join(', ') : '-';
      }
      return '-';
    };

    function timeStringToPulse(timeString) {
      let pulses = '-';
      if (timeString?.includes(':')) {
        const [hours, minutes, seconds] = timeString.split(':').map(Number);
        pulses = Math.ceil(hours * 60 + minutes + (seconds > 0 ? 1 : 0));
        console.log("timeStringToPulse:if:", { pulses });
      } else {
        const totalSeconds = Number(timeString);
        pulses = Math.ceil(totalSeconds / 60);
        console.log("timeStringToPulse:else:", { pulses });
      }
      if (pulses === 0) return '-';
      return pulses;
    }

    return (
      <div className={`${this.props.className}`}>
        <Sidebar />
        <div className={this.props.sidebarClosed ? 'Content' : 'ContentFull'}>
          <Topbar />
          <div className="ContentContainer">
            <TabSearchBar tabs={tabs}>
              <SearchBar
                type="calls"
                disableTitle
                count={this.state.count}
                reset={this.getFreshList}
                search={this.seachHandler}
              />
            </TabSearchBar>

            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', marginBottom: '50px' }}>
              <DataTable.Table style={{ width: '100%', marginBottom: '2px' }} >
                {this.state.loading && <PreLoader />}
                <DataTable.Head>
                  {userRole !== 'USER' && (
                    <>
                      <DataTable.CellHead width="70">
                        AGENT NAME
                      </DataTable.CellHead>
                      {isUnansweredAgentEnabled && (
                        <DataTable.CellHead width="70">
                          UNANSWERED AGENTS
                        </DataTable.CellHead>
                      )}
                    </>
                  )}
                  <DataTable.CellHead width="70">
                    VIRTUAL NO
                  </DataTable.CellHead>
                  <DataTable.CellHead width="70">CALL TYPE</DataTable.CellHead>
                  <DataTable.CellHead width="70">DTMF CODE</DataTable.CellHead>
                  {/* <DataTable.CellHead width="70">GROUP/IVR</DataTable.CellHead> */}
                  <DataTable.CellHead width="70">CALLER</DataTable.CellHead>
                  <DataTable.CellHead width="70">CONTACT NAME</DataTable.CellHead>
                  <DataTable.CellHead width="70">NOTES</DataTable.CellHead>
                  <DataTable.CellHead width="70">TAGS</DataTable.CellHead>
                  <DataTable.CellHead width="70">CALL STATUS</DataTable.CellHead>
                  <DataTable.CellHead width="70">DURATION</DataTable.CellHead>
                  <DataTable.CellHead width="70">PULSE COUNT</DataTable.CellHead>
                  <DataTable.CellHead width="70">CALL RECORD</DataTable.CellHead>
                  <DataTable.CellHead width="50">TIME</DataTable.CellHead>
                  <DataTable.CellHead width="70">DATE</DataTable.CellHead>
                  <DataTable.CellHead width="20">ACTIONS</DataTable.CellHead>
                </DataTable.Head>
                <DataTable.Body>
                  {(callList || []).map((item) => (
                    <DataTable.Row key={item?._id}>
                      {userRole !== 'USER' && (
                        <>
                          <DataTable.Cell>
                            {(item.call_status_text === 'Missed' &&
                              item.is_group_call_log) || (item.call_status_text === 'Received')
                              ? '-'
                              : item.zvr_name || '-'}
                          </DataTable.Cell>

                          {isUnansweredAgentEnabled && (
                            <DataTable.Cell>
                              {item.busyUserList
                                ? item.busyUserList.join(', ')
                                : ''}
                            </DataTable.Cell>
                          )}
                        </>
                      )}
                      <DataTable.Cell>
                        {this.trimNumber(
                          item.is_group_call_log
                            ? item.group_v_mobile_no ?? item.v_mobile_no
                            : item.v_mobile_no
                        )}
                      </DataTable.Cell>
                      <DataTable.Cell>

                        {/* {item.ivrName && 'IVR'}
                      {item.routed_group && 'Group'}
                      {item.auto_attendant_id && 'Auto Attendant'} */}

                      {/* {(item.call_type === 'callBack') ? "Agent" :
                        item.ivrName ? 'IVR' :
                          item.routed_group ? 'Group' :
                            item.auto_attendant_id ? 'Auto Attendant' : "-"
                      } */}

                      {
                        item?.incoming_call_type || "-"
                      }

                        {/* {item.is_group_call_log && item.routed_group
                        ? item.routed_group
                        : item.ivrName
                        ? item.ivrName
                        : '-'} */}
                      </DataTable.Cell>
                      <DataTable.Cell>
                        {showDtmfCodes(item.ivr_details)}
                      </DataTable.Cell>
                      <DataTable.Cell>{item.s_mobile_no}</DataTable.Cell>
                      <DataTable.Cell>{item.contact_name || '-'}</DataTable.Cell>
                      <DataTable.Cell>
                        <div className="tableNotes">{item.notes || '-'}</div>
                      </DataTable.Cell>
                      <DataTable.Cell>
                        {item?.tags?.length > 0 &&
                          item.tags.map((tag, index) => {
                            return (
                              <div key={index} className="tableTags">
                                <img
                                  alt="tag"
                                  src={TagIcon}
                                  style={{
                                    width: '15px',
                                    height: '15px',
                                    marginRight: 7,
                                  }}
                                />
                                <span>{tag}</span>
                              </div>
                            );
                          })}
                      </DataTable.Cell>
                      {/* call type */}
                      {item.call_status_text &&
                        (item.call_status_text === 'Missed' ? (
                          <DataTable.CellMissedCall>
                            {item.call_status_text}
                          </DataTable.CellMissedCall>
                        ) : item.call_status_text === 'Answered' ? (
                          <DataTable.CellAnsweredCall>
                            {item.call_status_text}
                          </DataTable.CellAnsweredCall>
                        ) : (
                          <DataTable.Cell>{item.call_status_text}</DataTable.Cell>
                        ))}
                      <DataTable.Cell>
                        {item.callBackId
                          ? item.duration === 0
                            ? '-'
                            : moment('1900-01-01 00:00:00')
                              .add(item.call_duration, 'seconds')
                              .format('HH:mm:ss')
                          : item.call_duration &&
                            item.call_duration !== '00:00:00'
                            ? item.call_duration
                            : '-'}
                      </DataTable.Cell>
                      <DataTable.Cell>
                        {timeStringToPulse(item?.call_duration)}
                      </DataTable.Cell>
                      <DataTable.Cell>
                        <AudioPlayer
                          callRecordingUrl={item?.call_recording_url}
                          callId={item.callBackId ? item?._id : item?.call_id}
                        />
                      </DataTable.Cell>
                      <DataTable.Cell>
                        {moment(item.created_at).format('HH:mm:ss')}
                      </DataTable.Cell>
                      <DataTable.Cell>
                        {' '}
                        {moment(item.created_at).format('DD-MM-YYYY')}
                      </DataTable.Cell>
                      <DataTable.Cell>
                        <div style={{ display: 'flex', gap: 5 }}>
                          {item.call_status_text === 'Answered' &&
                            userRole === 'ADMIN' &&
                            localStorage.getItem('is_ai_intelligence_enabled') === 'true' &&
                            <AICallAnalysisPopup
                              callId={item._id?.toString()}
                              audio={item.call_recording_url}
                              agentName={item?.zvr_name}
                              name={item?.contact_name}
                              number={item?.s_mobile_no}
                              duration={item.call_duration_seconds}
                              time={item.created_at}
                              date={item.created_at}
                              outgoing={false}
                              disposition={item?.disposition}
                            />}
                          {item.is_contact ? (
                            <ViewContactPopup
                              key={item._id}
                              contactInfo={item.contactObj}
                              getList={this.getFreshList}
                              editFrom="callLog"
                              userId={item.user_id}
                            />
                          ) : (
                            isSuperAdminWithPermission('EDIT') &&
                            userRole === 'USER' && (
                              <AddContactNotesTagsPopup
                                callerNo={item.s_mobile_no}
                                getList={this.getFreshList}
                              />
                            )
                          )}
                        </div>
                      </DataTable.Cell>
                    </DataTable.Row>
                  ))}
                </DataTable.Body>
              </DataTable.Table>
            </div>
            <Paginator
              currentPage={this.state.currentPage}
              lastPage={this.state.totalPages}
              getInfo={(value) => this.fetchPageData(value)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  callForwardingStatus: state.topbar.call_forwarding_status,
  isOrgOutboundCallEnabled: state.topbar.isOrgOutboundCallEnabled,
  isOutboundCallEnabled: state.topbar.isOutboundCallEnabled,
  isUnansweredAgentEnabled: state.topbar.isUnansweredAgentEnabled,
});

export default commonStyle(connect(mapStateToProps, {})(CallLogsPage));
