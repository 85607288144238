import react from "react";
import moment from "moment";
import { GET } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';

const subscriptionUrl = process.env.REACT_APP_SUBSCRIPTION_API;

const RenewBanner = () => {
  const expiryDate = localStorage.getItem('organisation_expiry_date');
  let isFreeTrail = JSON.parse(localStorage.getItem('is_freemium_plan_used'));
  let showRenew = localStorage.getItem("organisation_inRenewPeriod")==='true';

  const calculateDaysLeft = (expiryDate) => {
    if (!expiryDate) return null;

    const currentDate = moment();
    const expiryMoment = moment(expiryDate);
    return expiryMoment.diff(currentDate, 'days');
  };

   const daysLeft = calculateDaysLeft(expiryDate);
  // const showRenewalBanner = daysLeft > 0 && daysLeft <= 10;

  const requestCallBackHandler = async () => {
    try {
      const res = await GET(`${subscriptionUrl}v1/payment-link/renew`);
      window.location.href = res.data.response;
    } catch (err) {
      toast.error(err?.response?.data?.error?.reason || 'An error occurred');
    }
  };

  return (showRenew && !isFreeTrail) ? (
    <>
      <div className="free-trial-banner">
        <span className="free-trial-text">
          Your account will expire in {daysLeft} days.
          <span
            className="free-trial-upgrade"
            style={{
              padding: '5px',
              fontWeight: 450
            }}
            onClick={requestCallBackHandler}
          >
            Renew now
          </span>
        </span>
      </div>
      <div style={{ height: '30px' }} />
    </>
  ) : null;
};

export default RenewBanner;