import React, { useEffect, useState } from "react";
import CallFlowStyle from "../callFlow/CallFlow.css";
import { useDispatch, useSelector } from 'react-redux';
import { SET_SIDEBAR_OPEN } from "../../actions/callFlowActions";
import Phone from "../../assets/images/phone_callback.png";
import Audio from "../../assets/images/audio_file.png";
import SupportAgent from "../../assets/images/support_agent.png";
import GroupIcon from "../../assets/images/group.png";
import Dialpad from "../../assets/images/dialpad.png";
import Group1 from "../../assets/images/Group1.png";
import { toast } from 'react-toastify';

const Menubar = ({ handleSaveData, dids, agents, groups }) => {
  const dispatch = useDispatch();
  const [ivrNumber, setIvrNumber] = useState("");
  const [audioFile, setAudioFile] = useState(null);
  const [agent, setAgent] = useState("");
  const [group, setGroup] = useState("");
  const dailPadValues = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"];
  const [selectedNumber, setSelectedNumbers] = useState([]);
  const handleDailPadSelect = (value) => {
    if (selectedNumber.includes(value)) {
      setSelectedNumbers(selectedNumber.filter((num) => num !== value));
    } else {
      setSelectedNumbers([...selectedNumber, value]);
    }
  }

  const closeSideBar = () => {
    dispatch({ type: SET_SIDEBAR_OPEN, payload: { isSideBarOpen: false  }})
  }
  const menuName = useSelector(state => state?.callFlow?.menuName);
  const nodeId = useSelector(state => state?.callFlow?.id);
  const data = useSelector(state => state?.callFlow?.data);

  useEffect(() => {
    if (menuName === "IVR" && data) {
      setIvrNumber(data)
    }
    if (menuName === "MENU" && data) {
      setSelectedNumbers(data);
    }
    if (menuName === "AUDIO" && data) {
      setAudioFile(data);
    }
    if (menuName === "AGENT" && data) {
      setAgent({
        zvr_name: data?.label,
        zvr_mobile_no: data?.additionalInfo?.mobileNo,
        user_id: data?.additionalInfo?.userId
      });
    }
    if (menuName === "GROUP" && data) {
      setGroup({
        name: data?.label,
        _id: data?.additionalInfo?.groupId
      });
    }
  }, [data])

  const handleIvrChange = (event) => {
    if (event.target.value) {
      setIvrNumber(event.target.value);
    }
  }

  const handleAgentChange = (event) => {
    if (event?.user_id) {
      setAgent(event);
    }
  }

  const handleGroupChange = (groupData) => {
    if (groupData?._id) {
      setGroup(groupData);
    };
  }

  const handleSave = () => {
    switch (menuName) {
      case "IVR": 
        if (!ivrNumber) {
          toast.error("Please Select IVR Number");
        } else {
          handleSaveData(nodeId, { label: ivrNumber, additionalInfo: '' } );
          closeSideBar();
        }
      break;
      case "AUDIO":
        if (!audioFile) {
          toast.error("Please Select Audio file");
        } else {
          handleSaveData(nodeId, { label: audioFile?.name, additionalInfo: 'AUTOATTENDANT', audioFile: audioFile } );
          closeSideBar();
        }
      break;
      case "AGENT":
        if (!agent?.user_id) {
          toast.error("Please Select Agent");
        } else {
          handleSaveData(nodeId, { label: agent?.zvr_name, additionalInfo: { mobileNo: agent?.zvr_mobile_no, userId: agent?.user_id } } );
          closeSideBar();
        }
      break;
      case "GROUP":
        if (!group?._id) {
          toast.error("Please Select Group");
        } else {
          handleSaveData(nodeId, { label: group?.name, additionalInfo: { groupId: group?._id } } );
          closeSideBar();
        }
      break;
      case "MENU":
        if (selectedNumber?.length <= 0 || !audioFile) {
          toast.error("Please Select Menu Options and Audio");
        } else {
          handleSaveData(nodeId, { label: selectedNumber, additionalInfo: 'MENU', audioFile: audioFile } );
          closeSideBar();
        }
      break
    }
  }

  const handleFileChange  = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAudioFile(file);
    }
  }
  return (
    <div className="SidebarBox" >
      <div className="SideBarHeader">
        <text onClick={closeSideBar} className="CancelText">CANCEL</text>
        <text className="SaveText" onClick={handleSave}>SAVE</text>
      </div>
      {
        menuName === "IVR"
          ? <div className="SideBarBody">
            <div style={{ display: "flex", flexDirection: "row", marginTop: "86px", marginLeft: "40px" }}>
              <img style={{ width: "24px", height: "24px", marginTop: "3px" }} src={Phone} />
              <h4 className="StartIvrHeading" style={{ marginLeft: "5px" }}>Start IVR</h4>
            </div>
            <h4 className="SubHeading">Select a number that begins this IVR program</h4>
            <h6 className="SubHeading" style={{ color: 'black', marginTop: "40px" }}>Select number</h6>
            <div className="HeaderFooter">
              <select value={ivrNumber} onChange={handleIvrChange} className="FooterSelectBox">
              <option value="" >
                --select--
              </option>
                {
                  dids?.map((did) => (
                    <option value={did}>{did}</option>
                  ))
                }
              </select>
            </div>
          </div>
        :  menuName === "AUDIO"
            ? <div className="SideBarBody">
              <div style={{ display: "flex", flexDirection: "row", marginTop: "86px", marginLeft: "40px" }}>
                <img style={{ width: "24px", height: "24px", marginTop: "3px" }} src={Audio} />
                <h4 className="StartIvrHeading" style={{ marginLeft: "5px", color: "#235789" }}>Play Audio</h4>
              </div>
              <h4 className="SubHeading">Select an audio file</h4>
              <div className="UploadAudioButton">
              <input
                type="file"
                id="audioFile"
                accept="audio/*"
                onChange={handleFileChange}
                style={{ display: 'none', cursor: "pointer" }}
              />
              <label  htmlFor="audioFile"  className="UploadAudioText">
                Upload audio
              </label>
              </div>
              {audioFile && <p className="SubHeading">{audioFile.name}</p>}
            </div> 
        : menuName === "MENU"
        ? <div className="SideBarBody">
        <div style={{ display: "flex", flexDirection: "row", marginTop: "34px", marginLeft: "40px" }}>
          <img style={{ width: "24px", height: "24px", marginTop: "3px" }} src={Dialpad} />
          <h4 className="StartIvrHeading" style={{ marginLeft: "5px", color: "#5C5C5C" }}>Menu</h4>
        </div>
        <h4 className="SubHeading">Configure dial options for customers on call</h4>
        <div className={`DailpadContainer`}>
          {dailPadValues.map((x) => {
            const valueSelected = selectedNumber?.includes(x);
            return (
              <div onClick={() => handleDailPadSelect(x)} className={`DailpadItem ${valueSelected ? `DailPadSelected` : ''}`}>
                <text className="DailpadText">{x}</text>
                {
                  valueSelected &&  <img className="DailPadTickMark" src={Group1} />
                }
              </div>
            )
          })}
          {/* <div className="UploadAudioButton">
          <input
            type="file"
            id="audioFile"
            accept="audio/*"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <label  htmlFor="audioFile"  className="UploadAudioText">
            Upload audio
          </label>
          </div> */}
        </div>
        <div className="MenuAudioContainer">
            <div style={{ display: "flex", flexDirection: "row" }}>
                <img style={{ width: "24px", height: "24px" }} src={Audio} />
                <h4 className="StartIvrHeading" style={{ marginLeft: "5px", color: "#235789", whiteSpace: 'wrap' }}>Play Audio</h4>
            </div>
            <h4 className="MenuAudioSubHeading">Select an audio file that plays for customers when they’re on this menu</h4>
            {/* <audio controls src={""} /> */}
            <div className="UploadAudioButton" style={{ marginLeft: "0px" }}>
              <input
                type="file"
                id="audioFile"
                accept="audio/*"
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
              <label  htmlFor="audioFile"  className="UploadAudioText">
                Upload audio
              </label>
              </div>
          </div>
      </div>
        : menuName === "AGENT"
        ? <div className="SideBarBody">
        <div style={{ display: "flex", flexDirection: "row", marginTop: "86px", marginLeft: "40px" }}>
          <img style={{ width: "24px", height: "24px", marginTop: "3px" }} src={SupportAgent} />
          <h4 className="StartIvrHeading" style={{ marginLeft: "5px", color: "#D55672" }}>Agent</h4>
        </div>
        <h4 className="SubHeading">Select an agent who answers calls in this flow</h4>
        <h6 className="SubHeading" style={{ color: 'black', marginTop: "40px" }}>Select agent</h6>
        <div className="HeaderFooter">
          <select value={agent?.zvr_name} onChange={(e) => handleAgentChange(agents[e.target.selectedIndex - 1])} className="FooterSelectBox">
          <option value="" >
            --select--
          </option>
          {
            agents?.map((agent) => (
              <option value={agent?.zvr_name}>{agent?.zvr_name}</option>
            ))
          }
          </select>
        </div>
      </div>
        : menuName === "GROUP"
        ? <div className="SideBarBody">
        <div style={{ display: "flex", flexDirection: "row", marginTop: "86px", marginLeft: "40px" }}>
          <img style={{ width: "24px", height: "24px", marginTop: "3px" }} src={GroupIcon} />
          <h4 className="StartIvrHeading" style={{ marginLeft: "5px", color: "#6B4ACE" }}>Group</h4>
        </div>
        <h4 className="SubHeading">Select a group who answers calls in this flow</h4>
        <h6 className="SubHeading" style={{ color: 'black', marginTop: "40px" }}>Select group</h6>
        <div className="HeaderFooter">
          <select value={group?.name} onChange={(e) => handleGroupChange(groups[e.target.selectedIndex - 1])} className="FooterSelectBox">
          <option value="--select--" >
            --select--
         </option>
          {
            groups?.map((x) => (
              <option value={x?.name}>{x?.name}</option>
            ))
          }
          </select>
        </div>
      </div>
      : ""
      }
    </div>
  )
};

export default Menubar;