import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { GET, POST } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import Success from '../../../assets/images/greenSuccessBig.png';
import { CheckboxChecked } from '../../../assets/Icons/Icons.js';
import PreLoader from '../preLoader/PreLoader.js';

const planUrl = process.env.REACT_APP_PLAN_API;
const subUrl = process.env.REACT_APP_SUBSCRIPTION_API;

const overlayStyle = { background: 'rgba(0,0,0,0.9)', overflow: 'auto' };
const contentStyle = {
  width: 'fit-content',
  fontFamily: 'Inter',
  padding: '40px 42px',
  borderRadius: '8px',
  boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.16)',
  backgroundColor: '#fff',
};

const Header = styled.p`
  font-size: 36px;
  font-weight: 500;
  margin: 0;
  // margin-bottom: 40px;
`;

const SubHead = styled.p`
  font-size: 16px;
  width: 800px;
  text-align: center;
  margin-bottom: 20px;
`;

const CapsuleButton = styled.button`
  width: 190px;
  // height: 35px;
  font-size: 16px;
  font-weight: 500;
  background-color: #fff;
  border-radius: 32px;
  color: #f8153b;
  border: 1px solid #f8153b;
  margin: 0;
  margin: auto;
  margin-bottom: 20px;
`;

const SecondarySelection = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: #a8a8b5;
  cursor: pointer;
`;

const Card = styled.div`
  box-shadow: 0px 1.92px 11.51px 0px #14142b0f;
  width: 376px;
  border: 0.96px 0px 0px 0px;
  border-radius: 16px;
  background: #ffffff;
  border: 0.96px solid #e6e6eb;
  display: flex;
  flex-direction: column;
  // align-items: center;
  padding: 24px;
`;

export default function UpgradePlanPopup(props) {
  const [plansList, setPlansList] = useState([]);
  console.log({ plansList });
  const [planType, setPlanType] = useState('MINUTE_BASED');
  const [planDuration, setPlanDuration] = useState('ANNUAL');
  const [sortedPlans, setSortedPlans] = useState([]);
  const [fetchingPaymentUrl, setFetchingPaymentUrl] = useState(false);
  let sortedArray = [];
  let a = [];
  let b = [];
  let c = [];
  console.log('props hrer', props);
  const fetchPlans = async () => {
    await GET(planUrl + 'v1/alohaa/plans?pageNo=1&pageSize=500')
      .then((res) => {
        let plans = res?.data?.response?.plans || [];
        plans = plans.filter((obj) => obj.isVisibleToClient == true);
        plans.sort((a, b) => {
          const aValue = JSON.stringify(Object.values(a).sort());
          const bValue = JSON.stringify(Object.values(b).sort());
          if (aValue < bValue) return -1;
          if (aValue > bValue) return 1;
          return 0;
        });
        setPlansList(plans);
      })
      .catch((err) => {
        console.log('error', err.message);
      });
  };

  const redirectToPayment = async (planCode) => {
    setFetchingPaymentUrl(true);
    localStorage.setItem('plan_before_upgrade', props?.currentPlan);
    await POST(subUrl + 'v1/payment-link/update', { planCode })
      .then((res) => {
        window.location.href = res?.data?.response?.data;
        setFetchingPaymentUrl(false);
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.error?.reason || 'Error fetching payment link'
        );
        setFetchingPaymentUrl(false);
      });
  };

  if (planType === 'MINUTE_BASED') {
    a = [
      'Incoming & Outgoing Call',
      'PSTN Calling',
      '1 Virtual Phone Number',
      'Up to 2 Agents',
      'Unlimited Contacts',
      '1000 Call Balance',
      'Basic Dialer',
      'Call Metrics & Recording',
      '₹0.6 for Single-leg Calling',
      'Bulk SMS',
      'Caller Tune',
      'Voice Broadcast (100 free credits)',
    ];
    b = [
      'All Basic Plan Features',
      '1 Additional Virtual Phone Number',
      'Up to 50 Agents',
      '12,000 Call Balance',
      'Multilevel IVR',
      'Call Routing',
      'Dynamic Dialer (Preview)',
      'External APIs (Call & Whatsapp)',
      'Webhook & API (50,000 Triggers Each)',
      '₹0.5 for Single-leg Calling',
      'Email Reports',
      'Missed Call Alerts',
    ];

    c = [
      'All Premium Plan Features',
      'Up to 100 Agents',
      '15,000 Call Balance',
      'Browser Based Calling (WebRTC)',
      'Dynamic Dialer (Predictive)',
      'Webhook & API (100,000 Triggers Each)',
      '₹0.4 for Single-leg Calling',
    ];
  } else {
    a = [
      'Incoming Call',
      'Dedicated Channel',
      '1 Virtual Phone Number/Agent',
      'PSTN Calling',
      'Multilevel IVR',
      'Call Grouping',
      'Caller Tune',
      'Autoattendant',
      'Unlimited Contacts',
      'Call Metrics & Recording',
      'Disposition',
      'External APIs(Call & Whatsapp)',
    ];
    b = [
      'All Basic Plan Features',
      'Outgoing Call',
      'Webhook1 (50,000 Triggers)',
      'Dynamic Dialer(Preview)',
    ];
    c = [
      'All Premium Plan Features',
      'Webhook1 (1,00,000 Triggers)',
      'Browser Based Calling (WebRTC)',
      'Dynamic Dialer (Predictive)',
    ];
  }

  useEffect(() => {
    setPlanType(props.currentProduct);
    fetchPlans();
  }, [props.currentProduct]);

  useEffect(() => {
    fetchPlans();
  }, []);

  useEffect(() => {
    let tenure = 'ANNUAL';
    if (props?.currentTenure) {
      tenure =
        props.currentTenure === 'MONTHLY' || props.currentTenure === '2WEEKS'
          ? 'ANNUAL'
          : props?.currentTenure;
      setPlanDuration(
        props.currentTenure === 'MONTHLY' || props.currentTenure === '2WEEKS'
          ? 'ANNUAL'
          : props?.currentTenure
      );
    }
    console.log('----planType----', planType);
    let initialSort = plansList.filter((item) => {
      return item.product === planType && item.tenure === tenure;
    });
    for (let i = 0; i < initialSort.length; i++) {
      if (initialSort[i].plan === 'BASIC') {
        const updatedFeatures = a.map((item) => {
          if (item.includes('Call Balance')) {
            return `${initialSort[i].callMinutes} Call Balance`;
          }
          if (item.includes('Agents')) {
            return `Up to ${initialSort[i].agentsCount} Agents`;
          }
          if (item.includes('Webhook & API')) {
            return `Webhook & API (${initialSort[i].webhookTriggersCount} Triggers Each)`;
          }
          if (item.includes('Webhook1')) {
            return `Webhook (${initialSort[i].webhookTriggersCount} Triggers)`;
          }
          return item;
        });
        initialSort[i].feat = updatedFeatures;
      }
      if (initialSort[i].plan === 'PREMIUM') {
        const updatedFeatures = b.map((item) => {
          if (item.includes('Call Balance')) {
            return `${initialSort[i].callMinutes} Call Balance`;
          }
          if (item.includes('Agents')) {
            return `Up to ${initialSort[i].agentsCount} Agents`;
          }
          if (item.includes('Webhook & API')) {
            return `Webhook & API (${initialSort[i].webhookTriggersCount} Triggers Each)`;
          }
          if (item.includes('Webhook1')) {
            return `Webhook (${initialSort[i].webhookTriggersCount} Triggers)`;
          }
          return item;
        });
        initialSort[i].feat = updatedFeatures;
      }
      if (initialSort[i].plan === 'PROFESSIONAL') {
        const updatedFeatures = c.map((item) => {
          if (item.includes('Call Balance')) {
            return `${initialSort[i].callMinutes} Call Balance`;
          }
          if (item.includes('Agents')) {
            return `Up to ${initialSort[i].agentsCount} Agents`;
          }
          if (item.includes('Webhook & API')) {
            return `Webhook & API (${initialSort[i].webhookTriggersCount} Triggers Each)`;
          }
          if (item.includes('Webhook1')) {
            return `Webhook (${initialSort[i].webhookTriggersCount} Triggers)`;
          }
          return item;
        });
        initialSort[i].feat = updatedFeatures;
      }
    }
    initialSort.sort((a, b) => a.price - b.price);
    setSortedPlans(initialSort);
  }, [props?.open, plansList]);

  useEffect(() => {
    sortedArray = plansList.filter((item) => {
      return item.product === planType && item.tenure === planDuration;
    });
    for (let i = 0; i < sortedArray.length; i++) {
      if (sortedArray[i].plan === 'BASIC') {
        const updatedFeatures = a.map((item) => {
          if (item.includes('Call Balance')) {
            return `${sortedArray[i].callMinutes} Call Balance`;
          }
          if (item.includes('Agents')) {
            return `Up to ${sortedArray[i].agentsCount} Agents`;
          }
          if (item.includes('Webhook & API')) {
            return `Webhook & API (${sortedArray[i].webhookTriggersCount} Triggers Each)`;
          }
          if (item.includes('Webhook1')) {
            return `Webhook (${sortedArray[i].webhookTriggersCount} Triggers)`;
          }
          return item;
        });
        sortedArray[i].feat = updatedFeatures;
      }
      if (sortedArray[i].plan === 'PREMIUM') {
        const updatedFeatures = b.map((item) => {
          if (item.includes('Call Balance')) {
            return `${sortedArray[i].callMinutes} Call Balance`;
          }
          if (item.includes('Agents')) {
            return `Up to ${sortedArray[i].agentsCount} Agents`;
          }
          if (item.includes('Webhook & API')) {
            return `Webhook & API (${sortedArray[i].webhookTriggersCount} Triggers Each)`;
          }
          if (item.includes('Webhook1')) {
            return `Webhook (${sortedArray[i].webhookTriggersCount} Triggers)`;
          }
          return item;
        });
        sortedArray[i].feat = updatedFeatures;
      }
      if (sortedArray[i].plan === 'PROFESSIONAL') {
        const updatedFeatures = c.map((item) => {
          if (item.includes('Call Balance')) {
            return `${sortedArray[i].callMinutes} Call Balance`;
          }
          if (item.includes('Agents')) {
            return `Up to ${sortedArray[i].agentsCount} Agents`;
          }
          if (item.includes('Webhook & API')) {
            return `Webhook & API (${sortedArray[i].webhookTriggersCount} Triggers Each)`;
          }
          if (item.includes('Webhook1')) {
            return `Webhook (${sortedArray[i].webhookTriggersCount} Triggers)`;
          }
          return item;
        });
        sortedArray[i].feat = updatedFeatures;
      }
    }

    sortedArray.sort((a, b) => a.price - b.price);
    console.log({ sortedArray });
    setSortedPlans(sortedArray);
  }, [planDuration, planType]);

  function determineUpgradeOrDowngrade(
    current,
    input,
    currentTenure,
    inputTenure
  ) {
    // Define the hierarchy
    const levels = {
      BASIC: 1,
      PREMIUM: 2,
      PROFESSIONAL: 3,
    };

    const tenures = {
      QUARTERLY: 2,
      HALF_YEARLY: 3,
      ANNUAL: 4,
    };
    if (planType === 'UNLIMITED') {
      tenures.MONTHLY = 1;
    }
    if (
      !(current in levels) ||
      !(input in levels) ||
      !(currentTenure in tenures) ||
      !(inputTenure in tenures)
    ) {
      // Check if current and input are valid
      return 'UPGRADE';
    }

    if (
      levels[input] === levels[current] &&
      tenures[inputTenure] === tenures[currentTenure]
    ) {
      return 'CURRENT';
    }

    // Determine if it's an upgrade or downgrade based on tenure first
    // console.log('comp tenure', inputTenure, currentTenure);
    if (tenures[inputTenure] >= tenures[currentTenure]) {
      console.log(
        'comp plan',
        inputTenure,
        currentTenure,
        input === 'PREMIUM',
        current
      );
      if (levels[input] >= levels[current]) {
        return 'UPGRADE';
      } else if (levels[input] === levels[current]) {
        return 'UPGRADE';
      } else if (levels[input] < levels[current]) {
        return 'NOT APPLICABLE';
      } else {
        return 'no change';
      }
    } else if (tenures[inputTenure] < tenures[currentTenure]) {
      return 'NOT APPLICABLE';
    }
    return '---';
  }

  if (props.channelPartner === 'SHIP_ROCKET') {
    return (
      <Popup
        open={props.open}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        {...{ overlayStyle, contentStyle }}
      >
        {fetchingPaymentUrl && <PreLoader />}
        <div
          style={{
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p
            onClick={props.close}
            style={{
              color: '#f8153b',
              fontWeight: 'bold',
              marginLeft: 'auto',
              cursor: 'pointer',
            }}
          >
            Close
          </p>
          <Header>Unlock All Premium Features</Header>
          <SubHead>
            Clicking the button below will direct you to the ShipRocket App
            Store. Please confirm the one-time charge of ₹2,360 (with GST) to
            enable all premium features for one year.
          </SubHead>
          <div style={{ display: 'flex', gap: '16px' }}>
            <CapsuleButton
              onClick={() => {
                redirectToPayment('sr-pre-my');
              }}
              style={
                null
                // planType === 'MINUTE_BASED'
                //   ?
                //   : null
                // {
                //     color: '#fff',
                //     backgroundColor: '#f8153b',
                //   }
              }
            >
              Subscribe Now
            </CapsuleButton>
          </div>
          <p
            style={{
              'margin-top': '24px',
              color: 'grey',
              'font-size': '14px',
              'margin-bottom': '0px',
            }}
          >
            For assistance, reach out to{' '}
            <a href="mailto:support@alohaa.ai">support@alohaa.ai</a>
          </p>
        </div>
      </Popup>
    );
  }

  return (
    <Popup
      open={props.open}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      {...{ overlayStyle, contentStyle }}
    >
      {fetchingPaymentUrl && <PreLoader />}
      <div
        style={{
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <p
          onClick={props.close}
          style={{
            color: '#f8153b',
            fontWeight: 'bold',
            marginLeft: 'auto',
            cursor: 'pointer',
          }}
        >
          Close
        </p>
        <Header>Pricing for all use cases</Header>
        <SubHead>
          Choose the perfect plan for your organization—whether you're just
          starting out, scaling up, or leading the market. Discover streamlined
          solutions designed for every stage of growth.
        </SubHead>
        {props.currentTenure === 'MONTHLY' ||
        props.currentTenure === '2WEEKS' ? (
          <div style={{ display: 'flex', gap: '16px' }}>
            <CapsuleButton
              onClick={() => {
                setPlanType('MINUTE_BASED');
                setPlanDuration('ANNUAL');
              }}
              style={
                planType === 'MINUTE_BASED'
                  ? {
                      color: '#fff',
                      backgroundColor: '#f8153b',
                    }
                  : null
              }
            >
              MINUTE BASED
            </CapsuleButton>

            <CapsuleButton
              style={
                planType === 'UNLIMITED'
                  ? {
                      color: '#fff',
                      backgroundColor: '#f8153b',
                    }
                  : null
              }
              onClick={() => setPlanType('UNLIMITED')}
            >
              UNLIMITED
            </CapsuleButton>
          </div>
        ) : null}
        {/* {props.currentTenure === 'MONTHLY' ? ( */}
        <div style={{ display: 'flex', gap: '32px', marginBottom: '20px' }}>
          <SecondarySelection
            onClick={() => setPlanDuration('ANNUAL')}
            style={planDuration === 'ANNUAL' ? { color: '#f8153b' } : null}
          >
            ANNUAL
          </SecondarySelection>
          <SecondarySelection
            onClick={() => setPlanDuration('HALF_YEARLY')}
            style={planDuration === 'HALF_YEARLY' ? { color: '#f8153b' } : null}
          >
            HALF YEARLY
          </SecondarySelection>
          <SecondarySelection
            onClick={() => setPlanDuration('QUARTERLY')}
            style={planDuration === 'QUARTERLY' ? { color: '#f8153b' } : null}
          >
            QUARTERLY
          </SecondarySelection>
          {planType === 'UNLIMITED' ? (
            <SecondarySelection
              onClick={() => setPlanDuration('MONTHLY')}
              style={planDuration === 'MONTHLY' ? { color: '#f8153b' } : null}
            >
              MONTHLY
            </SecondarySelection>
          ) : null}
        </div>
        {/* ) : null} */}
        <div style={{ display: 'flex', gap: '16px' }}>
          {sortedPlans.map((card) => (
            <Card key={card.price}>
              <p style={{ fontSize: '40px', fontWeight: '500', margin: 0 }}>
                {card?.plan}
              </p>
              <p style={{ fontSize: '28px', fontWeight: '500' }}>
                {/* ₹{card?.price} */}
                {card.price && card.price > 0 ? (
                  `₹${card?.price.toLocaleString('en-US')}`
                ) : (
                  <br></br>
                )}
              </p>
              {card.price ? (
                <CapsuleButton
                  style={
                    ['NOT APPLICABLE', 'CURRENT'].includes(
                      determineUpgradeOrDowngrade(
                        props?.currentPlan,
                        card.plan,
                        props?.currentTenure,
                        card.tenure
                      )
                    )
                      ? {
                          margin: '0 auto 14px',
                          color: '#a8a8b5',
                          borderColor: '#a8a8b5',
                          cursor: 'default',
                        }
                      : { margin: '0 auto 14px' }
                  }
                  onClick={() => {
                    if (
                      determineUpgradeOrDowngrade(
                        props?.currentPlan,
                        card.plan,
                        props?.currentTenure,
                        card.tenure
                      ) === 'UPGRADE'
                    ) {
                      redirectToPayment(card.planCode);
                    }
                  }}
                >
                  {determineUpgradeOrDowngrade(
                    props?.currentPlan,
                    card.plan,
                    props?.currentTenure,
                    card.tenure
                  )}
                </CapsuleButton>
              ) : null}
              {!card.price ? (
                <CapsuleButton
                  style={{
                    margin: '0 auto 14px',
                    cursor: 'default',
                    color: '#A8A8B5',
                    border: '1px solid #A8A8B5',
                  }}
                >
                  NOT APPLICABLE
                  {/* {(props?.currentPlan === 'FREEMIUM'|| props?.currentPlan==='14DAY-FREEMIUM' )
                    ? 'CURRENT'
                    : 'NOT APPLICABLE'} */}
                </CapsuleButton>
              ) : null}

              <div style={{ fontSize: '18px', fontWeight: '500' }}>
                {card?.feat?.length > 0 &&
                  card.feat.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        gap: '12px',
                        alignItems: 'baseline',
                        margin: '6px',
                      }}
                    >
                      <CheckboxChecked />
                      <p style={{ margin: 0 }}>{item}</p>
                    </div>
                  ))}
              </div>
            </Card>
          ))}
        </div>
      </div>
    </Popup>
  );
}
